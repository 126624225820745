import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { IPrintParam } from '../../models/print-param';

export interface TextData{
  mode:'verse'|'greeting'|'decoration',
  printParam: IPrintParam
}

@Component({
  selector: 'app-text-edit',
  templateUrl: './text-edit.component.html',
  styleUrls: ['./text-edit.component.scss']
})
export class TextEditComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: TextData,
  public dialogRef: MatDialogRef<TextEditComponent>,) {

    
   }

  ngOnInit(): void {
  }

  closeDialog(){
    this.dialogRef.close();
  }

  openEditDialog(){

  
  }
  // onChange(event){
  //   let e = event as string

  //   e = e.replace('\r','r<br/>')
  //   e = e.replace('\n','n<br/>')
    

  //   console.log(e)
  // }
}
