import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { map, tap } from 'rxjs';
import { CategoriesService } from '../../services/categories.service';
import { ImagesService } from '../../services/images.service';
export interface ImgData {
  img: any
}

@Component({
  selector: 'app-image-edit',
  templateUrl: './image-edit.component.html',
  styleUrls: ['./image-edit.component.scss'],
})
export class ImageEditComponent implements OnInit {

  typeList = ['textBackground', 'appBackground']

  ngOnInit() { }

  constructor(@Inject(MAT_DIALOG_DATA) public data: ImgData,
    public dialogRef: MatDialogRef<ImageEditComponent>,
    private imgSer: ImagesService,
    private cs: CategoriesService) {

  }

  categories$ = this.imgSer.categories$.pipe(map(c => _.chain(c).map(d => _.map(d.categories, e => ({ ...e, name: _.capitalize(e.name) }))).flatten().value()), tap(console.log));

  img$ = this.imgSer.getImages$(null).pipe(map(i => i.find(l => l.listItem.code == this.data.img.code)), tap(i => console.log({ i })))



  process(img: any) {
    console.log({ img })
    var t = _.cloneDeep(img)
    delete t.listItem

    this.imgSer.updateImage(t)
    // need to reset cache
  }

  deleteImg(img: any) {
    console.log({ img })

    var t = _.cloneDeep(img)
    delete t.listItem
    this.imgSer.deleteImage(t)

  }

  closeDialog() {
    this.dialogRef.close();
  }
}
