import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { tap } from 'rxjs';
import { CategoriesService } from '../../services/categories.service';
import * as _ from 'lodash'
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {

  addOnBlur = false;
  readonly separatorKeyCodes = [ENTER, COMMA] as const;


  constructor(private cs: CategoriesService) { }

  categories$ = this.cs.categories$.pipe(tap(console.log));

  ngOnInit() { }
  drop(catType: any, event: CdkDragDrop<any[]>) {
    moveItemInArray(catType.categories, event.previousIndex, event.currentIndex);

    console.log({ cat: catType.categories, event })
    catType.categories.forEach((c, i) => c.id = i)
    this.cs.updateCategories(catType)

  }
  remove(catType: any, cat): void {
    const index = _.findIndex(catType.categories, cat);

    if (index >= 0) {
      catType.categories.splice(index, 1);
    }
    // console.log({i:index,c:catType.categories, cat})
    this.cs.updateCategories(catType)
  }
  add(catType: any, event: MatChipInputEvent): void {
    const v = (event.value || '').trim();
    var id = catType.categories.length


    var index = _.findIndex(catType.categories, (c: any) => c.name.toLowerCase() == v)
    console.log({ v, catType, index })
    if (v && index < 0) {

      catType.categories.push({ id: id, name: v });
      this.cs.updateCategories(catType)
    }
    event.chipInput!.clear();
    // this._ser.searchKey.next(this.keys);
    // this._me.cast(LocalEvent.SearchKeyEntiy, this.keys);
    //this.eb.set(Events.SearchKeyEntiy, this.keys);

    // this.br.setSearchKey(this.keys)

    // this.searchSer.setSearchKey(this.keys)

    console.log({ v });

  }

}
