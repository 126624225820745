import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { map, tap } from 'rxjs';
import { CategoriesService } from '../../services/categories.service';
import { ImagesService } from '../../services/images.service';
export interface ImgData {
  img: File
}

@Component({
  selector: 'app-image-add',
  templateUrl: './image-add.component.html',
  styleUrls: ['./image-add.component.scss'],
})
export class ImageAddComponent implements OnInit {

  typeList = ['textBackground', 'appBackground']

  img :any;
  imgType:string='textBackground';

  constructor(@Inject(MAT_DIALOG_DATA) public data: ImgData,
    public dialogRef: MatDialogRef<ImageAddComponent>,
    private imgSer: ImagesService,
    private cs: CategoriesService) {
    console.log({ data })
    var reader = new FileReader();
  
    reader.onload = (event) => {
      // this.updateAppbackgroundCustomized("")    
      let src = event.target?.result;
      console.log({src})

      this.img = src;

      // let r = document.documentElement;
      // let sty = getComputedStyle(r)
      // r.style.setProperty('--backGroundImg', `url('${src}')`)


    };
  
    reader.readAsDataURL(data.img);

  }

  ngOnInit() { }
  categories$ = this.imgSer.categories$.pipe(map(c => _.chain(c).filter(cc => cc.type == 'textBackground').map(d => _.map(d.categories, e => ({ ...e, name: _.capitalize(e.name) }))).flatten().value()), tap(console.log));

  img$ = null//this.imgSer.getImages$(null).pipe(map(i=> i.find(l=>l.listItem.code == this.data.img.code)),tap(i=>console.log({i})))


  category: string

  process() {
    console.log({ img:this.data, cat:this.category })
    // var t = _.cloneDeep(img)
    // delete t.listItem

     this.imgSer.addImage({uri:this.img, name:this.data.img.name, category:this.category,type:this.imgType})

    // need to reset cache

  }



  
  closeDialog() {
    this.dialogRef.close();
  }

}
