import { Component, OnInit } from '@angular/core';
import { BibleVersionsService } from '../../services/bible-versions.service';

@Component({
  selector: 'app-bible-versions',
  templateUrl: './bible-versions.component.html',
  styleUrls: ['./bible-versions.component.scss'],
})
export class BibleVersionsComponent implements OnInit {

  constructor(private bvs : BibleVersionsService) { }

  ngOnInit() {}

  versions$ = this.bvs.versions$;

  changeHide(version){
    this.bvs.changeHide(version);
  }
}
