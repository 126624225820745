import { CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import {
  Component,
  ChangeDetectionStrategy,
  ElementRef,
  Output,
  EventEmitter,
  OnInit,
  AfterViewInit,
  Input,
} from '@angular/core';

import { Subject, merge, of } from 'rxjs';
import { tap, auditTime, withLatestFrom } from 'rxjs/operators';
import * as _ from "lodash";
import { PrintService } from '../../services/print.service';
import { PrintRepo } from '../../print.repository';
import { IPrintParam, PrintParam } from '../../models/print-param';
import { MatDialog } from '@angular/material/dialog';
import { TextEditComponent } from '../text-edit/text-edit.component';
import { GalleryComponent } from '../gallery/gallery.component';
import { SharedRepository } from 'src/app/shared/shared.repository';
import { ImageEditorComponent } from '../image-editor/image-editor.component';

export interface movingPoint {
  x: number,
  y: number
}

// export interface resizePoint{
//   width:number,
//   height:number
// }
@Component({
  selector: '[app-print-handle]',
  templateUrl: './print-handle.component.html',
  styleUrls: ['./print-handle.component.scss']
})
export class PrintHandleComponent implements OnInit, AfterViewInit {

  ngOnInit(): void { }

  @Input('app-print-handle') startPoint: movingPoint

  @Input('boundary') boundary: string

  // decoration leave for later enhancement
  @Input('mode') mode: 'verse' | 'decoration' | 'greeting' = 'verse'

  @Input('disabled') disabled:boolean = false;


  // @Output() moving = new EventEmitter<movingPoint>();
  // @Output() resize = new EventEmitter<resizePoint>();
  printParam$ = this.pr.printParam$

  private startPoint$ = new Subject<movingPoint>();
  private dragMove$ = new Subject<CdkDragMove>();
  private dragMoveAudited$ = this.dragMove$.pipe(
    withLatestFrom(this.startPoint$, this.printParam$),
    auditTime(16),
    tap(([{ distance }, rect, param]) => {
       console.log({mode:this.mode, distance, rect})

      _.set(param!, `${this.mode}Format.top`, rect.y + distance.y)
      _.set(param!, `${this.mode}Format.left`, rect.x + distance.x)



      // param!.verseFormat.top = rect.y + distance.y;
      // param!.verseFormat.left = rect.x + distance.x;


      // this.moving.emit({x:rect.x + distance.x, y:rect.y + distance.y})
      // this.el.nativeElement.style.width = `${rect.x + distance.x}px`;
      // this.el.nativeElement.style.height = `${rect.y + distance.y}px`;
    })
  );

  sub$ = merge(this.dragMoveAudited$, of(true));

  constructor(private el: ElementRef<HTMLElement>, private ps: PrintService,
    private pr: PrintRepo,   public dialog: MatDialog, private shr: SharedRepository) {

  }
  imageBaseUrl$ = this.shr.imageBaseUrl$;

  openTextEdit( p: IPrintParam){
    this.dialog.open(TextEditComponent, {
      panelClass: 'shareDialogPanel',
      width: '300px',
      //  height:'300px',
      // position: { top: '50px', right: '50px' },
      data: {
        mode:this.mode,
        printParam:p
      },
    });
  }
  
  openGallery( ){
    this.dialog.open(GalleryComponent, {
      panelClass: 'shareDialogPanel',
      width: '500px',
      // height:'50%',
      autoFocus:"#focuse",
       position: { bottom: '20%' },
      data: {
        mode:this.mode
      },
    });
  
  }
  
  _ = _;
  
  ngAfterViewInit(): void {
    // console.log({ start:this.el.nativeElement.getBoundingClientRect()})
  }

  dragStarted(): void {
    // let m = _.pick(this.el.nativeElement.getBoundingClientRect(),['x','y'] )
    // this.startPoint$.next(m);
    // console.log({m, start:this.el.nativeElement.getBoundingClientRect()})

    this.startPoint$.next(this.startPoint)
  }

  dragEnded($event: CdkDragEnd): void {
    $event.source._dragRef.reset();

    // this.resized.emit(this.el.nativeElement.getBoundingClientRect());
    // this.el.nativeElement.focus()
  }

  dragMoved(event: CdkDragMove): void {
    this.dragMove$.next(event);
    // console.log({moving:event})
  }

  // resize start................................

  private startSize$ = new Subject<DOMRect>();
  private resizeMove$ = new Subject<CdkDragMove>();
  private resizeMoveAudited$ = this.resizeMove$.pipe(
    withLatestFrom(this.startSize$, this.printParam$),
    auditTime(16),
    tap(([{ distance }, rect, param]) => {
      // console.log({distance, sty:this.el.nativeElement.style.width})
      let w = rect.width + distance.x;
      let h = rect.height + distance.y;

      _.set(param!, `${this.mode}Format.width`, w)
      _.set(param!, `${this.mode}Format.height`, h)

      // this.el.nativeElement.style.width = `${w}px`;
      // this.el.nativeElement.style.height = `${h}px`;
      // this.resize.emit({width:w, height:h});
    })
  );

  subResize$ = merge(this.resizeMoveAudited$, of(true));

  resizeStarted(): void {
    this.startSize$.next(this.el.nativeElement.getBoundingClientRect());
  }

  resizeEnded($event: CdkDragEnd): void {
    $event.source._dragRef.reset();
    // this.resized.emit(this.el.nativeElement.getBoundingClientRect());
    // this.el.nativeElement.focus()
  }

  resizeMoved($event: CdkDragMove): void {
    this.resizeMove$.next($event);
    // console.log({el:this.el})
  }


  // font handle................
  startCounterA( inc: boolean) {
    let p = `${this.mode}Format.fontSize`
    this.ps.startCounter(p, inc)
  }

  startCounterB( inc: boolean) {
    let p = `${this.mode}Format.fontSize${this.mode =='verse'? 'Ind':''}`
    this.ps.startCounter(p, inc)
  }

  stopCounter() {
    this.ps.stopCounter()
  }

  process(p: IPrintParam) {
    this.ps.process(p)
  }

//   getImage(p:IPrintParam){
//     return _.
//   mode=='verse'?printParam.verseFormat.backgroundImg: 
//         mode=='greeting'?printParam.greetingFormat.backgroundImg:printParam.verseFormat.backgroundImg
// }

testc(e:any){
  console.log({e})
}



upload(event:any,printParam:IPrintParam,mode:string){
  switch(mode){
    case "greeting":
      this.ps.setPartABackgroundImage(event,printParam);
      break;
      case "verse":  
      default:
      this.ps.setPartBBackgroundImage(event,printParam);
      break;
  }
  
}

openImageEditor(p:IPrintParam,mode?:string){

  this.dialog.open(ImageEditorComponent, {
    panelClass: 'shareDialogPanel',
    width: '90%',
     height:'90%',
    autoFocus:"#focuse",
     position: { top: '1%' },
    data: {
      mode:mode,
      
      printParam:p
    },
  });

  console.log({p,mode})
}


}
