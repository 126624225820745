import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedRepository } from 'src/app/shared/shared.repository';
import { PrintRepo } from '../../print.repository';

import { default as ImageEditor } from "tui-image-editor";
import { IPrintParam } from '../../models/print-param';
import { HttpClient } from '@angular/common/http';

export interface imgData {
  mode: 'verse' | 'greeting' | 'decoration',
  printParam: IPrintParam
}

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss'],
})
export class ImageEditorComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data: imgData,

    public dialogRef: MatDialogRef<ImageEditorComponent>,
    private pr: PrintRepo,
    private http: HttpClient,
    private shr: SharedRepository) {  }

  instance: any




  ngOnInit() {

    let e = document.querySelector('#t-image-editor')
    console.log({ e });


    



    let p = this.data.printParam;
    let src = p.verseFormat.backgroundImgUrl

    if (src) {
      console.log({ src })
      this.initTUI(src);
      this.updatePrintRepo(src,p);
    }
    else {
     

      src = `https://printout.bible/background/${p.verseFormat.backgroundImg}${p.verseFormat.backgroundImg.length < 5 ? '.jpg' : ''}?Cor`

      this.http.get(src, { responseType: 'blob' })
      .subscribe(r => {
        this.updateImageFromBlob(r,p)
        console.log({ srca:p.verseFormat.backgroundImgUrl })

        // this.initTUI(p.verseFormat.backgroundImgUrl)

      })


    }

    


  }

  updateImageFromBlob(image: Blob, printParam: IPrintParam) {

    var reader = new FileReader();

    let pr = this.pr;

    reader.onload =  (event) => {
      // this.updateAppbackgroundCustomized("")    
      let src = event.target?.result;

      printParam.verseFormat.backgroundImgUrl = src as string;
      console.log({ src, paramUrl: printParam.verseFormat.backgroundImgUrl })
      pr.updatePrintParam(printParam)

       this.initTUI(printParam.verseFormat.backgroundImgUrl)
    };

    reader.readAsDataURL(image);
  }

  updatePrintRepo(src: any, printParam: IPrintParam) {
    let pr = this.pr;

    printParam.verseFormat.backgroundImgUrl = src as string;
    console.log({ src, paramUrl: printParam.greetingFormat.backgroundImgUrl })
    pr.updatePrintParam(printParam)

  }

  initTUI(src: any) {


    this.instance = new ImageEditor(document.getElementById('t-image-editor') as Element, {
      includeUI: {
        loadImage: {
          path: src,
          name: 'SampleImage'
        },
        theme: {
          // 'menu.backgroundColor': 'rgba(0, 0, 100, 0.5)',
          'common.backgroundColor': 'rgba(050, 50, 050, 0.5)',
          'menu.normalIcon.path': 'assets/svg/icon-d.svg',
          'menu.normalIcon.name': 'icon-d',

          'menu.activeIcon.path': 'assets/svg/icon-b.svg',
          'menu.activeIcon.name': 'icon-b',
          'menu.iconSize.width': '24px',
          'menu.iconSize.height': '24px',

          'submenu.backgroundColor': 'rgba(0, 0, 100, 0.5)',
          'submenu.normalIcon.path': 'assets/svg/icon-d.svg',
          'submenu.normalIcon.name': 'icon-d',
          'submenu.activeIcon.path': 'assets/svg/icon-b.svg',
          'submenu.activeIcon.name': 'icon-b',
          'submenu.iconSize.width': '32px',
          'submenu.iconSize.height': '32px'
        },
        initMenu: 'filter',
        menuBarPosition: 'bottom'
        
      },


      selectionStyle: {
        cornerSize: 20,
        rotatingPointOffset: 70,
      },


    });

  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    // this.instance.loadImageFromURL('https://printout.bible/background/1.jpg', 'myImage') // or loadImageFromURL

    //     let p = this.data.printParam
    //     let src =  p.verseFormat.backgroundImgUrl ?? `https://printout.bible/background/${p.verseFormat.backgroundImg}${p.verseFormat.backgroundImg.length<5?'.jpg':''}`
    // console.log({src})
    //     if(this.instance){
    //       // this.instance.loadImkageFromURL(src, 'myImage').then(r=>
    //       //   {console.log({r});

    //       // this.instance.ui.activeMenuEvent()
    //       // })
    //     }


  }

  onSave() {

    var src = this.instance.toDataURL();
    this.data.printParam.verseFormat.backgroundImgUrl = src as string;
    console.log({ src, paramUrl: this.data.printParam.verseFormat.backgroundImgUrl })
    this.pr.updatePrintParam(this.data.printParam)



    this.dialogRef.close();
  }
  onCancel() {
    this.dialogRef.close();
  }



}
