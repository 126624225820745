import { Injectable } from '@angular/core';
import { map, mergeMap, tap, take } from 'rxjs';
import { BackendService } from 'src/app/shared/services/backend.service';
import { FeatherService } from 'src/app/shared/services/feather.service';
import { SharedRepository } from 'src/app/shared/shared.repository';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(private bs:BackendService, private shr:SharedRepository, private fs: FeatherService) { }

  categories$ = this.shr.categoriesAdminUrl$.pipe(
    tap(uu=>console.log({uu})),
    mergeMap(u=> this.bs.get1<any>(u)),
    tap(uuu=>console.log({uuu})),
    )

  updateCategories(catType:any)  {
    this.shr.categoriesAdminPath$.pipe(
      take(1),
      mergeMap(u=>this.fs.update(u, catType._id, catType))
    ).subscribe()

    // this.shr.categoriesAdminUrl$.pipe(
    //   tap(uu=>console.log({uu})),
    //   map(u=> this.bs.put(u+"/"+catType._id,catType)),
    //   // tap(uuu=>console.log({uuu})),
    //   ).subscribe()

  }
}
