import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { BoardComponent } from './components/board/board.component';
import { MaterialModule } from '../shared/material/material.module';
import { CategoriesComponent } from './components/categories/categories.component';
import { StarsComponent } from './components/stars/stars.component';
import { ImagesComponent } from './components/images/images.component';
import { ImageEditComponent } from './components/image-edit/image-edit.component';
import { StarEditComponent } from './components/star-edit/star-edit.component';
import { StarAddComponent } from './components/star-add/star-add.component';
import { ImageAddComponent } from './components/image-add/image-add.component';
import { BibleVersionsComponent } from './components/bible-versions/bible-versions.component';
import { LogComponent } from './components/log/log.component';
import { UserComponent } from './components/user/user.component';
import { FeedbackComponent } from './components/feedback/feedback.component';




@NgModule({
  declarations: [BoardComponent, CategoriesComponent, StarsComponent, ImagesComponent, ImageEditComponent, StarEditComponent, StarAddComponent, ImageAddComponent, BibleVersionsComponent, LogComponent, UserComponent, FeedbackComponent],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule
  ]
})
export class AdminModule { }
