import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-search-toolbar',
  templateUrl: './search-toolbar.component.html',
  styleUrls: ['./search-toolbar.component.scss'],
})
export class SearchToolbarComponent implements OnInit {

  selectable = true;
  removable = true;
  addOnBlur = false;
  readonly separatorKeyCodes = [ENTER, COMMA] as const;

  keys: string[] = [];
  constructor(private searchSer: SearchService) {
    searchSer.setSearchKey([]);

    // searchSer.search$({key:'1'}).subscribe(r=>console.log({r}))

  }

  ngOnInit() { }


  add(event: MatChipInputEvent): void {
    const v = (event.value || '').trim();
    if (v) {
      // this.keys.splice(0,0,{ key: v });
      this.keys.push(v);
    }
    event.chipInput!.clear();
    // this._ser.searchKey.next(this.keys);
    // this._me.cast(LocalEvent.SearchKeyEntiy, this.keys);
    //this.eb.set(Events.SearchKeyEntiy, this.keys);

    // this.br.setSearchKey(this.keys)

    this.searchSer.setSearchKey(this.keys)

    console.log({ v });
  }


  remove(key: string): void {
    console.log(key);


    const i = this.keys.findIndex(a => a == key);
    if (i > -1) {

      this.keys.splice(i, 1)
    }
    // this._ser.searchKey.next(this.keys);
    // this._me.cast(LocalEvent.SearchKeyEntiy, this.keys);
    // this.eb.set(Events.SearchKeyEntiy, this.keys);

    // this.br.setSearchKey(this.keys);

    this.searchSer.setSearchKey(this.keys)
  }

  changeSearchType(event: MatSlideToggleChange) {
    let a = event.checked;
    console.log(a);
    this.searchSer.searchType.next(a? 'and': 'or')
    // this._ser.searchOr.next(!a);
    // this._me.cast(LocalEvent.SearchOrEntity, !a);

    // this.br.setSearchOr(!a);

    //this.eb.set(Events.SearchOrEntity, !a);

  }
  changeSearchOrdered(event: MatSlideToggleChange) {
    let a = event.checked;
    console.log(a);
    this.searchSer.searchOrdered.next(a)
  }
  changeSearchScope(event: MatSlideToggleChange) {
    let a = !event.checked;
    console.log(a);
    this.searchSer.searchMain.next(a)
  }


}
