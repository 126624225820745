import { Injectable } from '@angular/core';
import { Store, createState, createStore } from '@ngneat/elf';
import { withEntities, selectEntities, setEntities, addEntities, deleteEntities } from '@ngneat/elf-entities';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';

export interface History {
  _id:number
  book:number,
  chapter:number,
  date:Date

  
}

// const { state, config } = <>;
// const store = new Store({ name: 'history', state, config });


export const historyStore = createStore({name:'history'}, withEntities<History,'_id'>({idKey:'_id'}))

export const persist = persistState(historyStore,{
  key:'history@store',
  storage:localStorageStrategy
})


@Injectable({ providedIn: 'root' })
export class HistoryRepository {
  history$ = historyStore.pipe(selectEntities());

  setHistory(history: History[]) {
    historyStore.update(setEntities(history));
  }

  addHistory(history: History) {
    console.log({addhist:history})
    historyStore.update(addEntities(history));
  }

  deleteHistory(id: History['_id']) {
    historyStore.update(deleteEntities(id));
  }

}
