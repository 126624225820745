import { Injectable } from '@angular/core';
import { SharedRepository } from 'src/app/shared/shared.repository';
import { combineLatest, map, mergeMap,take, tap } from 'rxjs';
import { BackendService } from 'src/app/shared/services/backend.service';
import { FeatherService } from 'src/app/shared/services/feather.service';

@Injectable({
  providedIn: 'root'
})
export class BibleVersionsService {
  

  constructor(private sr: SharedRepository,private bs: BackendService, private fs: FeatherService) { }

  versions$ = this.sr.versions$.pipe(tap(s=>console.log({s})));

  changeHide(version: any) {
    this.sr.sharedstore.update(st=> ({...st, versions:st.versions.map(v=>({...v, hide:v.code==version.code ? version.hide : v.hide}))}));


    this.sr.versionsAdminPath$.
    pipe(
      tap(a=>console.log({a})),
      take(1),
      mergeMap(u=>this.fs.update(u, version._id,version))
    ).subscribe()

    // this.sr.changeHide(version);
  }
}
