import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map, tap } from 'rxjs';
import { CategoriesService } from '../../services/categories.service';
import { StarsService } from '../../services/stars.service';
import { StarEditComponent } from '../star-edit/star-edit.component';
import * as _ from "lodash";
@Component({
  selector: 'app-stars',
  templateUrl: './stars.component.html',
  styleUrls: ['./stars.component.scss'],
})
export class StarsComponent implements OnInit {
  

  constructor(private ss : StarsService,
    private cs:CategoriesService,
    public dialog: MatDialog) { }

  stars$ = this.ss.stars$.pipe(tap(s=>console.log({s})));

  // topics$ = this.cs.categories$.pipe(map(c=> c.catType.type=="star"))

  topics$ = this.cs.categories$.pipe(map(c=> 
    _.chain(c)
    .filter(cc=>cc.type=='star')
    .map(d=> _.map(d.categories, dd=>dd.name ))
    .flatMap()
    .value()))

  ngOnInit() {}

  edit(topics,item){
    console.log({item})
    this.dialog.open(StarEditComponent, {
      panelClass: 'shareDialogPanel',
      width: '500px',
        height:'300px',
      // position: { top: '50px', right: '50px' },
      data: {
       star:item,
       topics:topics
      },
    });
  }
}
