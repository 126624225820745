import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {

  constructor(private us: UserService) { }
  users$ = this.us.users$.pipe(map((u:any)=>u.data)) ;

  ngOnInit() {}

}
