import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { map } from 'rxjs';
import { LogService } from '../../services/log.service';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss'],
})
export class LogComponent implements OnInit {

  constructor(private ls:LogService) { }
  log$ = this.ls.log$.pipe(map((u:any)=>_.orderBy(u.data, 'date', 'desc')));

  ngOnInit() {}

}
