import { Injectable } from '@angular/core';


import * as feathersRx from 'feathers-reactive';
// import * as io from 'socket.io-client';

import feathers from '@feathersjs/feathers';
// import feathersSocketIOClient from '@feathersjs/socketio-client';
import feathersAuthClient2 from '@feathersjs/authentication-client';
import { AuthenticationRequest } from '@feathersjs/authentication/lib';
import * as rest from "@feathersjs/rest-client";

import { HttpClient, HttpHeaders, HttpXhrBackend } from "@angular/common/http";
import { defer, from, startWith, take } from 'rxjs';
import { SharedRepository } from '../shared.repository';
import * as _ from 'lodash'


// import { xhr2 } from "xhr2";

@Injectable({
  providedIn: 'root'
})
export class FeatherService {
  private _feathers = feathers();                     // init socket.io
  // private _socket = io('http://localhost:3030');  
  private _rest;// =  rest(await this.shr.baseUrl$.pipe(take(1)).toPromise());

  // init feathers
  // private feathersAuthClient = require('@feathersjs/authentication-client').default;
  createAngularHTTPClient() {
    const serverXHR = { build: () => new XMLHttpRequest() };
    return new HttpClient(
      new HttpXhrBackend(serverXHR)
    );
  }
  private _angularHttpClient = this.createAngularHTTPClient();

  constructor(private shr: SharedRepository) {

    this.shr.baseUrl$.pipe(take(1)).subscribe(
      burl => {
        this._rest = rest(burl);
        this._feathers
          .configure(this._rest.angularHttpClient(this._angularHttpClient, { HttpHeaders }))
          // .configure(feathersSocketIOClient(this._socket))  // add socket.io plugin
          .configure(feathersAuthClient2({                   // add authentication plugin
            storage: window.localStorage,
            path: 'api/authentication'
          }))
          .configure(feathersRx({                           // add feathers-reactive plugin
            idField: '_id'
          })
          )
          ;
      }

    )



  }

  // expose services
  public service(name: string) {
    return this._feathers.service(name);
  }

  // expose authentication
  public authenticate(credentials?: AuthenticationRequest | undefined): Promise<any> {
    return this._feathers.authenticate(credentials);
  }

  // expose logout
  public logout() {
    return this._feathers.logout();
  }

  fea() {
    return this._feathers
  }
  // public getAuthentication(){
  //  return  from(this._feathers.reAuthenticate())
  // }
  // public  isAuthenticated$ =  defer(()=>this._feathers.reAuthenticate())

  gethistService() {
    return this._feathers.service("api/history")
  }

  find(url: string) {

    return from(this._feathers.service(_.trimStart(url,'/')).find())
  }
  update(url: string, id: any, data: any) {
    return from(this._feathers.service(_.trimStart(url,'/')).update(id, data))
  }

  create(url: string, data: any) {
    return from(this._feathers.service(_.trimStart(url,'/')).create(data))
  }
  remove(url: string, id: any) {
    return from(this._feathers.service(_.trimStart(url,'/')).remove(id))
  }

}