import { Injectable } from '@angular/core';
import { HttpClient, HttpParams  } from "@angular/common/http";
import { EMPTY, map, Observable, switchMap, take, tap } from 'rxjs';
import { FeatherService } from './feather.service';

@Injectable({
  providedIn: 'root'
})
export class BackendHttpService {

  constructor( private h:HttpClient, private fs: FeatherService) { }

  get1<T>(url :string){
    return this.h.get(url)
    .pipe(
      //  tap(console.log),
       // temporary change for hisotry and book mark
    map(m=> (m as any)?.data ? (m as any)?.data as T : (m as T) )
    )
  }

  get<T>(url :string, params?:any ){
    return this.h.get(url,{params})
    .pipe(
      //  tap(console.log),
       // temporary change for hisotry and book mark
    map(m=> (m as T) )
    )
  }

  get$(url :Observable<string>, params?:any ){
    return url.pipe(
      
      take(1),
      tap(console.log),
      switchMap(u=>this.fs.find(u)),
    );
   
  }

post(url:string, value:any ){
  console.log({post:url})
  var skipList = ['history','bookmark','setting']
  if(!skipList.some(k=>url.includes(k))){
    this.h.post(url,value).subscribe();
    console.log({posturl:url})
  }
  
}

post$(url: Observable<string>, value:any ){
  var skipList = ['history','bookmark','setting']
  
  return url.pipe(
    take(1),
    tap(console.log),
    switchMap(u=>{
      if(!skipList.some(k=>u.includes(k))){
        return this.h.post(u,value)
      }
      else{
        return EMPTY
      }
    })

  );
   
}


put(url:string, value:any ){
  var skipList = ['hisotry','bookmark','setting']
  if(!skipList.some(k=>url.includes(k))){
    console.log({puturl:url,value})
    this.h.put(url,value).subscribe();
    
  }

  // this.h.put(url,value).subscribe();
}

delete(url:string){
  this.h.delete(url).subscribe();
}
}
