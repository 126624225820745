import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { ImageListItem } from 'src/app/shared/models/image-list-item';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrintRepo } from '../../print.repository';
import { map, mergeMap, tap } from 'rxjs/operators';
import { IPrintParam } from '../../models/print-param';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { GalleryService } from '../../services/gallery.service';
import * as _ from 'lodash';
import { isNaN } from 'lodash';
import { combineLatest, forkJoin } from 'rxjs';
import { SharedRepository } from 'src/app/shared/shared.repository';

export interface imgData {
  mode: 'verse' | 'greeting' | 'decoration'

}

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit ,AfterViewInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: imgData,
    private gs: GalleryService,
    public dialogRef: MatDialogRef<GalleryComponent>, private pr: PrintRepo, private shr:SharedRepository) {

      // this.catAndImage$.subscribe(ccc=>console.log({ccc}));
     }
  ngAfterViewInit(): void {
    
    // document.getElementById('foc').click();


  }

  categories$ = this.gs.categories$
  .pipe(tap(console.log),
  
  map(c=> _.orderBy(c.find(cc=>cc.type =='textBackground').categories,'id'))

  // forkJoin(c=> _.chain(c).map(cc=> this.getImageList$(cc.name).pipe(map(d=>({category:cc,images:d}))) ).value())
  );

  ngOnInit() { }
  
  closeDialog() {
    this.dialogRef.close();
  }

  openEditDialog() {
  }


  getImageList$(l: string) {
    return combineLatest([this.gs.getTextBackImages$(l),this.shr.imageBaseUrl$])
      .pipe(
        
        map(([d,p]) => d.map(dd =>
        ({ src: dd == '999' ?  '' : dd.length<5 ? `${p}/${dd}.jpg`
        :`${p}/${dd}`, caption: `${dd}`, code: `${dd}` }))
      )
        // , tap(ii => console.log({ ii }))
      )
  }


  // catAndImage$ = this.categories$.pipe(mergeMap(c=> 
  //   forkJoin(_.chain(c).map(cc=> this.getImageList$(cc.name)).value())),
  // tap(ddd=>console.log({ddd})) );

  

  backgroundImageClick(img: ImageListItem) {
    console.log({ img })

    this.pr.updateBackgroundImg(img.code, this.data.mode);
  }

  // log(s:string){
  //   console.log({s});
  // }
}

