import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BibleToolbarService } from '../../services/bible-toolbar.service';

@Component({
  selector: 'app-bible-toolbar',
  templateUrl: './bible-toolbar.component.html',
  styleUrls: ['./bible-toolbar.component.scss'],
})
export class BibleToolbarComponent implements OnInit {




  constructor(private router: Router,
    private bts: BibleToolbarService,
 ) { }

  showprint$ = this.bts.showPrint$;
  showBookmark$ = this.bts.showBookmarkbutton$
  showChapterNav$ = this.bts.showChapterNav$
  showDelete$ = this.bts.showDeletehistory$
  ngOnInit(): void {
  }
  printOut() {
    // this.shr.startPrintAction();

    this.bts.printBible()
  }

  saveBookMark() {
    this.bts.saveBookmark()

  }

  goPrevious() {
    console.log('go prev')
    this.bts.goPrev()
  }
  goNext() {

    console.log('go next in toolbar')
    this.bts.goNext()

  }


  goNextHist() {

  }

  deleteHistory() {
    //todo...
   // this.hs.deleteHistory()
  }


}
