import { Injectable } from '@angular/core';
import { withProps, createStore, select } from '@ngneat/elf';
import { IPrintContent } from './models/iprint-content';
import * as _ from "lodash";
import { filter, map, tap } from 'rxjs';
import { IVersionSetting } from "./models/iversion-setting";
import { IVersion } from './models/iversion';
import { persistState, localStorageStrategy, excludeKeys } from "@ngneat/elf-persist-state";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SharedProps {
  bookNames: {} | null,
  versions: IVersion[] | null
  versionSetting: IVersionSetting[] | null,
  printContent: IPrintContent[] | null,
  ApiUrl: {
    base: string,
    setting: string,
    history: string,
    bookmark: string,
    versions: string,
    versionsAdmin: string,
    bible: string,
    star: string,
    image: string,
    imageAdmin: string,
    imageUpload: string,
    categories: string,
    categoriesAdmin: string,
    starsAdmin: string,
    cacheReset: string,
    imageBaseUrl: string,
    logApiPath: string,
    usersApiPath: string,
    feedbackApiPath: string,

  } | null,
  colNum: number,
  printAction: number,
  appbackground: string,
  appLanguage: string,
  isPortaitDevice: boolean,
  isHandsetLandscape: boolean,
  isHandset: boolean,
  images: [{ user: string, type: string, category: string, name: string }] | null,
  strEsvCopyright: string
}

// const { state, config } = createState( withProps<SharedProps>({
//     v:null
// }));

export const sharedStore =
  createStore({ name: 'shared' },
    withProps<SharedProps>({
      bookNames: null,
      versions: null,
      versionSetting: null,
      printContent: null,
      ApiUrl: null,
      colNum: Math.floor(window.innerWidth / 50),
      printAction: 0,
      appbackground: 'yellow-parchment.jpg',
      appLanguage: 'en-CA',
      isPortaitDevice: true,
      isHandsetLandscape: false,
      isHandset: false,
      images: null,
      strEsvCopyright:  "ESV® Bible (The Holy Bible, English Standard Version®), copyright © 2001 by Crossway, a publishing ministry of Good News Publishers. Used by permission. All rights reserved.The Holy Bible, English Standard Version (ESV) is adapted from the Revised Standard Version of the Bible, copyright Division of Christian Education of the National Council of the Churches of Christ in the U.S.A. All rights reserved.For information on how the ESV can be quoted, please visit our permission site (https://www.crossway.org/permissions/)."

    }));
// disable cache persistence  for now
export const persist = persistState(sharedStore, {
  key: 'shared@store',
  storage: localStorageStrategy,
  source: ()=> sharedStore.pipe(excludeKeys(['ApiUrl','bookNames','versions','images','strEsvCopyright']))
})

@Injectable({ providedIn: 'root' })
export class SharedRepository {

  //  sharedStore = sharedStore;
  constructor() {

  }
  sharedstore = sharedStore;

  filterValue = (f) => !_.isNaN(f) && !_.isNull(f) && !_.isUndefined(f)

  strEsvCopyright$ = sharedStore.pipe(select(s => s.strEsvCopyright), filter(h => this.filterValue(h)));

  main$ = sharedStore.pipe(select(s => s.versionSetting), map(v => v?.find(v => v.main)), filter(h => this.filterValue(h)));
  loaded$ = sharedStore.pipe(select(s => s.versionSetting), map(v => v?.filter(v => v.loaded)), filter(h => this.filterValue(h)));
  versionSetting$ = sharedStore.pipe(select(st => st.versionSetting), filter(h => this.filterValue(h)));
  // versionSetting$ = sharedStore.pipe(select(st=>st.versionSetting.filter(v=> st.versions.some(vr => vr.code==v.code && !vr.hide  )  )),filter(h=> this.filterValue(h) ));
  versions$ = sharedStore.pipe(select(st => st.versions), filter(h => this.filterValue(h)));

  isEsvMain$ = sharedStore.pipe(select(st => st.versionSetting),map(v => v.find(vv=> vv.main).code === 'esv'));
  // sharedstore = sharedStore.pipe(select(st=>st))

  textBackgroundImages$ = sharedStore.pipe(select(st => st.images?.filter(i => i.type == "textBackground")))

  appBackgroundImages$ = sharedStore.pipe(select(st => st.images?.filter(i => i.type == "appBackground")))


  images$ = sharedStore.pipe(select(st => st.images), filter(h => this.filterValue(h)))

  baseUrl$ = sharedStore.pipe(select(s => s.ApiUrl?.base), filter(h => this.filterValue(h)));

  settingUrl$ = sharedStore.pipe(select(s => s.ApiUrl?.base as string + s.ApiUrl?.setting), filter(s => this.filterValue(s)));

  historyUrl$ = sharedStore.pipe(select(s => s.ApiUrl?.base as string + s.ApiUrl?.history),
    filter(h => this.filterValue(h)));

  bookmarkUrl$ = sharedStore.pipe(select(s => s.ApiUrl?.base as string + s.ApiUrl?.bookmark),
    filter(h => this.filterValue(h)));

  versionsUrl$ = sharedStore.pipe(select(s => s.ApiUrl?.base as string + s.ApiUrl?.versions),
    filter(h => this.filterValue(h)));

  versionsAdminUrl$ = sharedStore.pipe(select(s => s.ApiUrl?.base as string + s.ApiUrl?.versionsAdmin),
    filter(h => this.filterValue(h)));
  versionsAdminPath$ = sharedStore.pipe(select(s => s.ApiUrl?.versionsAdmin),
    filter(h => this.filterValue(h)));

  cacheResetUrl$ = sharedStore.pipe(select(s => s.ApiUrl?.base as string + s.ApiUrl?.cacheReset),
    filter(h => this.filterValue(h)));

  cacheResetPath$ = sharedStore.pipe(select(s => s.ApiUrl?.cacheReset),
    filter(h => this.filterValue(h)));
  imageBaseUrl$ = sharedStore.pipe(select(s => s.ApiUrl?.imageBaseUrl),
    filter(h => this.filterValue(h)));



  bibleUrl$ = sharedStore.pipe(select(s => s.ApiUrl?.base as string + s.ApiUrl?.bible),
    filter(h => this.filterValue(h)));

  starUrl$ = sharedStore.pipe(select(s => s.ApiUrl?.base as string + s.ApiUrl?.star),
    filter(h => this.filterValue(h)));

  imageUrl$ = sharedStore.pipe(select(s => s.ApiUrl?.base as string + s.ApiUrl?.image),
    filter(h => this.filterValue(h)));
  imageAdminUrl$ = sharedStore.pipe(select(s => s.ApiUrl?.base as string + s.ApiUrl?.imageAdmin),
    filter(h => this.filterValue(h)));
  imageAdminPath$ = sharedStore.pipe(select(s => s.ApiUrl?.imageAdmin),
    filter(h => this.filterValue(h)));
  imageUploadUrl$ = sharedStore.pipe(select(s => s.ApiUrl?.base as string + s.ApiUrl?.imageUpload),
    filter(h => this.filterValue(h)));

  imageUploadPath$ = sharedStore.pipe(select(s => s.ApiUrl?.imageUpload),
    filter(h => this.filterValue(h)));

  categoriesUrl$ = sharedStore.pipe(select(s => s.ApiUrl?.base as string + s.ApiUrl?.categories),
    filter(h => this.filterValue(h)));

  categoriesAdminUrl$ = sharedStore.pipe(select(s => s.ApiUrl?.base as string + s.ApiUrl?.categoriesAdmin),
    filter(h => this.filterValue(h)));
  categoriesAdminPath$ = sharedStore.pipe(select(s => s.ApiUrl?.categoriesAdmin),
    filter(h => this.filterValue(h)));

  starsAdminUrl$ = sharedStore.pipe(select(s => s.ApiUrl?.base as string + s.ApiUrl?.starsAdmin),
    filter(h => this.filterValue(h)));

  starsAdminPath$ = sharedStore.pipe(select(s => s.ApiUrl?.starsAdmin),
    filter(h => this.filterValue(h)));


    logApiPath$ = sharedStore.pipe(select(s => s.ApiUrl?.logApiPath),
    filter(h => this.filterValue(h)));


  userApiPath$ = sharedStore.pipe(select(s => s.ApiUrl?.usersApiPath),
  filter(h => this.filterValue(h)));

  feedbackApiPath$ = sharedStore.pipe(select(s => s.ApiUrl?.feedbackApiPath),
  filter(h => this.filterValue(h)));



  printContent$ = sharedStore.pipe(select(s => s.printContent), filter(h => this.filterValue(h)));

  bookNames$ = sharedStore.pipe(select(st => st.bookNames), filter(h => this.filterValue(h)));

  columnNum$ = sharedStore.pipe(select(st => st.colNum));

  appbackground$ = sharedStore.pipe(select(st => st.appbackground));
  appLanguage$ = sharedStore.pipe(select(st => st.appLanguage));

  isPortaitDevice$ = sharedStore.pipe(select(st => st.isPortaitDevice));
  isHandsetLandscape$ = sharedStore.pipe(select(st => st.isHandsetLandscape))
  isHandset$ = sharedStore.pipe(select(st => st.isHandset))
  // main = 

  // loaded = sharedStore.getValue().versionSetting?.filter(v => v.loaded);

  baseUrl = sharedStore.getValue().ApiUrl?.base
  settingUrl = this.baseUrl as string + sharedStore.getValue().ApiUrl?.setting
  historyUrl = this.baseUrl as string + sharedStore.getValue().ApiUrl?.history

  getCurrentStore() {
    return sharedStore.getValue();
  }

  getMain() {
    return sharedStore.getValue().versionSetting?.find(v => v.main);
  }
  getLoaded() {
    return sharedStore.getValue().versionSetting?.filter(v => v.loaded);
  }

  getHistoryUrl() {
    return sharedStore.getValue().ApiUrl?.base as string + sharedStore.getValue().ApiUrl?.history
  }

  getSettingUrl() {
    return sharedStore.getValue().ApiUrl?.base as string + sharedStore.getValue().ApiUrl?.setting
  }
  getBookNames() {
    return sharedStore.getValue().bookNames;
  }


  setColumnsNum(c: number) {
    sharedStore.update(st => ({ ...st, colNum: c }));
  }

  printAction$ = sharedStore.pipe(select(st => st.printAction))

  startPrintAction() {
    sharedStore.update(st => ({ ...st, printAction: (st.printAction + 1) }))
  }

  printContent(c: any) {
    sharedStore.update(st => ({ ...st, printContent: c }));
  }

  updateAppbackground(b: string) {
    sharedStore.update(st => ({ ...st, appbackground: b }));
  }

  updateAppLanguage(l: string) {
    sharedStore.update(st => ({ ...st, appLanguage: l }));
  }
  updatePortaitStatus(isPortait: boolean) {
    sharedStore.update(st => ({ ...st, isPortaitDevice: isPortait }));
  }
  updateHandsetLandscape(isHandsetLandscape: boolean) {
    sharedStore.update(st => ({ ...st, isHandsetLandscape: isHandsetLandscape }));
  }

  updateHandset(isHandset: boolean) {
    sharedStore.update(st => ({ ...st, isHandset: isHandset }));
  }

}

