import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { BookmarkService } from '../../services/bookmark.service';
import { HistoryService } from '../../services/history.service';
import * as _ from 'lodash';
import { BibleToolbarService } from '../../services/bible-toolbar.service';
import { SelectionModel } from '@angular/cdk/collections';
import { SharedRepository } from 'src/app/shared/shared.repository';
import { combineLatest, Subject } from 'rxjs';
import { N } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit,OnDestroy {
  end = new Subject<void>()
  end$ = this.end.asObservable();
  selection = new SelectionModel<any>(true, []);

  isEsvMain$ = this.shr.isEsvMain$

  
  constructor(private hs: HistoryService,
    private bs: BookmarkService,
    private router: Router,
    private bts: BibleToolbarService,
    private shr: SharedRepository) {
    this.bts.printAction$
      .pipe(
        withLatestFrom(shr.main$, shr.versions$),
        tap(([a, m, v]) => {
          console.log('star print trigggered...')
          let zz = _.chain(this.selection.selected).map(a => {
            return {bookId: a.book, bookName: a.bookName, verseId: a.verse,
              chapterId: a.chapter, verseT: a.content,
              date: new Date(), version: m.code}
            // let bn = v.find(ver => ver.code == m.code)?.content?.find(b => b.id == a.verses[0]?.book)?.name

            console.log({a})
            return _.map(a,
              v => ({
                bookId: v.book, bookName: v.bookName, verseId: v.verse,
                chapterId: v.chapter, verseT: v.content,
                date: new Date(), version: m.code
              }))
          }).value()
           console.log({ zz })
          shr.printContent(zz)
          this.router.navigate([{ outlets: { primary: ['print'], toolbarLeft: null, toolbarRight: ['print'] } }]);
        }
        ),
        takeUntil(this.end$)
      ).subscribe()

     
  }

  ngOnInit() { }
  h$= this.hs.history$.pipe(
    withLatestFrom(this.hs.bookNames$),
    map(([h,n])=> _.chain(h).values().map(hh=>({chapter:hh.chapter,book:hh.book,bookName:n.find(nn=> nn.id == hh.book)?.name,date:hh.date.toLocaleString()})).orderBy('date','desc').value()),
    tap(hhh=>console.log({hhh}))
  )

  history$ = this.hs.getHistory$().pipe(
    withLatestFrom(this.hs.bookNames$),
    map(([h, n]) => _.orderBy(h.data.map(
      //hh => ({ chapter: hh.log.chapter, book: hh.log.book, bookName: n.find(nn => nn.id == hh.log.book)?.name,d:hh.date, date: (new Date(hh.date)).toLocaleString('en-US', { hour12: false }) })
      hh => ({ chapter: hh.log.chapter, book: hh.log.book, bookName: n.find(nn => nn.id == hh.log.book)?.name,d:hh.date, date: hh.date.toLocaleDateString() })
    ), 'd', 'desc')
    )
    //  ,tap(console.log)
  )
b$ = this.bs.bookmark$.pipe(tap(bbb=>console.log({bbb})),map(b=>_.chain(b).values().map(bb=> ({...bb, date: bb.date.toLocaleString()})).orderBy('date','desc').value()),tap(bb=>console.log({bb})));


isHistoryReturn$ = this.h$.pipe(map(a => a.length > 0))
isBookmarkReturn$ = this.b$.pipe(map(a => a.length > 0))

isShowCopyrightHistory$ = combineLatest([this.isEsvMain$, this.isHistoryReturn$]).pipe(map(([a, b]) => a && b ))
isShowCopyrightBookmark$ = combineLatest([this.isEsvMain$, this.isBookmarkReturn$]).pipe(map(([a, b]) => a && b))

strEsvCopyright$ = this.shr.strEsvCopyright$


  bookmark$ = this.bs.getBookmark$().pipe(
    withLatestFrom(this.hs.bookNames$),
    map(([b, n]) =>
      _.orderBy(b.data.map(bv => bv.data).flat().map(
        //bm => ({ ...bm, bookName: n.find(nn => nn.id == bm.book)?.name,d:bm.date, date: (new Date(bm.date)).toLocaleString('en-US', { hour12: false }) }))
        bm => ({ ...bm, bookName: n.find(nn => nn.id == bm.book)?.name,d:bm.date, date: bm.date }))
        , 'd', 'desc'))
    , tap(console.log)
  )

  onLinkClick(row: any) {
    console.log({ row })
    this.router.navigate([{ outlets: { primary: ['chapter', row.book, row.chapter, 1], toolbarLeft: ['chapter', row.book, row.chapter, 1], toolbarRight: null } }]);
  }

  checkChanged(item: any) {
    this.selection.toggle(item);
    console.log('checkchanged..')
    if (this.selection.selected.length > 0) {
      // this.eb.set(Events.ShowPrint, true)
      this.bts.showPrintButton()
    }
    else {
      // this.eb.set(Events.ShowPrint, false)
      this.bts.hidePrintButton()
    }
    console.log(this.selection.selected.length)
  }
  ngOnDestroy(): void {
    this.end.next();
    this.bts.hidePrintButton();
  }



}
