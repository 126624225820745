import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaterialModule } from "./material/material.module";
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';

import { fas } from '@fortawesome/free-solid-svg-icons';
import { SettingComponent } from './components/setting/setting.component';
import { UploadComponent } from './components/upload/upload.component';
import { ImageListComponent } from './components/image-list/image-list.component';
import { ImageListItemComponent } from './components/image-list-item/image-list-item.component';

import { TranslateLoader, TranslateModule, TranslateStore } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { HighlightPipe } from './pipes/highlight.pipe';
import { ShareLinkDialogComponent } from './components/share-link-dialog/share-link-dialog.component';
import { ColorPickerModule } from "ngx-color-picker";
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SignupVerificationComponent } from './components/signup-verification/signup-verification.component';
import { ResetPasswordRequestComponent } from './components/reset-password-request/reset-password-request.component';
import { LinebreakPipe } from './pipes/linebreak.pipe';


@NgModule({
  declarations: [
    SettingComponent,
    UploadComponent,
    ImageListComponent,
    ImageListItemComponent,
    HighlightPipe,
    ShareLinkDialogComponent,
    LoginComponent,
    LogoutComponent,
    RegisterComponent,
    ResetPasswordComponent,
    SignupVerificationComponent,
    ResetPasswordRequestComponent,
    LinebreakPipe],
  imports: [
    CommonModule,
    FontAwesomeModule,
    ColorPickerModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    FormsModule,
    HttpClientModule,
    MaterialModule,
    RouterModule
  ],
  exports: [
    MaterialModule,
    FontAwesomeModule,
    CommonModule,
    RouterModule,
    HighlightPipe,
    LinebreakPipe,
    ColorPickerModule,
    UploadComponent,
    TranslateModule,
    ImageListComponent,
    ImageListItemComponent,


  ],
  providers: [TranslateStore]
})
export class SharedModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }

}
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}