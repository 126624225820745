import * as _ from "lodash";
export class PrintParam implements IPrintParam {

        
  public constructor(init?:Partial<IPrintParam>){
  Object.assign(this,init)
  } 
  
  corner: string = "99";
  backgroundImg: string = "999";

  pageBackgroundColor: string = '#FFFFFF'
  color: string = "#000000"

  pageOrientation: boolean = false; // fasle : vertial

  pageLandscape: boolean = false


  // fontFamily: string = "FangZhengKaiTi";
  // fontSize: number = 40;
  // fontWeight: string = "normal";
  // fontStyle: string = "normal";


  // fontFamilyInd: string = "FangZhengKaiTi";
  // // fontSizeInd: number = 10;
  // fontWeightInd: string = "normal";
  // fontStyleInd: string = "normal";

  sourceGreeting: string = "put your greetings on this part"
  sourceVerse: string = "Please select words to print here" //"请先选择经文";
  sourceIndex: string = "Chapter ii";

  pagePosition: 'up' | 'down' = 'up'
  pageType: 'folded' | 'flat' = 'flat';
  pageOutputType: 'pdf' | 'png' = 'png';
  pageSize: string = "a4";
  pageSwitchSide: boolean = false;

  textMargin: number = 30;
  cornerSize: number = 5;
  cornerMargin: number = 3;
  printingFontFlag = false;

  verseFormat:ITextFormat=  {
    textOrientationV: false,
    fontFamily: "FangZhengKaiTi",
    fontSize: 10,
    fontWeight: "normal",
    fontStyle: "normal",

    fontColor: {},

    fontFamilyInd: "FangZhengKaiTi",
    fontSizeInd: 10,
    fontWeightInd: "normal",
    fontStyleInd: "normal",


    MarginTop: 10,
    MarginBottom: 10,
    MarginLeft: 10,
    MarginRight: 10,
    MarginAll: 10,
    textColor: "#000000",
    backgroundColor: '#FFFFFF',
    backgroundImgUrl: null,
    backgroundImg: '999',
    top: 50,
    left: 50,
    width: 0,
    height: 100

  }
  greetingFormat: ITextFormat= {
    textOrientationV: false,
    fontFamily: "FangZhengKaiTi",
    fontSize: 10,
    fontWeight: "normal",
    fontStyle: "normal",
    fontColor: {},

    fontFamilyInd: "FangZhengKaiTi",
    fontSizeInd: 10,
    fontWeightInd: "normal",
    fontStyleInd: "normal",

    MarginTop: 10,
    MarginBottom: 10,
    MarginLeft: 10,
    MarginRight: 10,
    MarginAll: 10,
    textColor: "#000000",
    backgroundColor:'#FFFFFF',
    backgroundImgUrl: null,
    backgroundImg: '999',
    top: 50,
    left: 50,
    width: 0,
    height: 100
  }

  lookups: ILookups = new Lookups()


}

export interface IPrintParam {

  backgroundImg: string

  corner: string;
  pageBackgroundColor: string
  color: string

  pageOrientation: boolean;
  pageLandscape: boolean



  // fontFamilyInd: string;
  // fontSizeInd: number;
  // fontWeightInd: string;
  // fontStyleInd: string;

  sourceVerse: string;
  sourceIndex: string;
  sourceGreeting: string;

  pagePosition: 'up' | 'down';
  pageType: 'folded' | 'flat';
  pageSize: string;
  pageSwitchSide: boolean;
  pageOutputType: 'pdf' | 'png' ;

  textMargin: number;
  cornerSize: number;
  cornerMargin: number;


  verseFormat: ITextFormat;
  greetingFormat: ITextFormat;

  // decoration:ITextFormat

  printingFontFlag: boolean;

  lookups: ILookups;

}
export interface ITextFormat {
  textOrientationV: boolean,
  fontFamily: string;
  fontSize: number;
  fontWeight: string;
  fontStyle: string;

  fontColor: any;



  fontFamilyInd: string;
  fontSizeInd: number;
  fontWeightInd: string;
  fontStyleInd: string;


  MarginTop: number;
  MarginBottom: number;
  MarginLeft: number;
  MarginRight: number;
  MarginAll: number;
  textColor: string;
  backgroundColor: string;
  backgroundImgUrl: string | null;
  backgroundImg: string | null;
  top: number;
  left: number;
  width: number;
  height: number;
}

export interface ISelectItem {
  value: string,
  caption: string
}
export interface IPageSize extends ISelectItem {
  ratio: number
}
export interface ILookups {
  
  cornerData: string[],
  backgroundImgData: string[],
  fontFamilyData: any[],
  pagePositionData: any[],
  pageSizeData: IPageSize[],
  pageTypeData: any[],
  pageOutputTypeData: any[],
  jspdfPaper:{[key:string]: number[]}
}

export class Lookups implements ILookups {

  backgroundImgData = _.chain(_.range(1, 18)).map(a => String(a + 100)).union(['999']).value();

  cornerData = _.chain(_.range(19)).difference([4, 9, 14]).map(a => _.padStart(a.toString(), 2, "0")).union(['99']).value();

  fontFamilyData: any[] = [
    { value: "FangZhengSongTi", caption: "宋体" },
    { value: "FangZhengFangSong", caption: "仿宋" },

    { value: "FangZhengKaiTi", caption: "楷体" },

    { value: "FangZhengHeiti", caption: "黑体" },
    // { value: "FanfZhengSongTiaFanTi", caption: "宋繁体" },
    // { value: "FangZhengKaiTiFanTi", caption: "楷繁体" }
    // { value: "HeitiFanTi", caption: "黑繁体" }
    { value: "Roboto", caption: "Roboto" },
    { value: "Pacifico", caption: "Pacifico" },
    { value: "Parisienne", caption: "Parisienne" }



  ]

  pagePositionData = [
    { value: "up", caption: "up" },
    { value: "down", caption: "down" }
  ]

  pageTypeData = [
    { value: "flat", caption: "Flat" },
    { value: "folded", caption: "Folded" }
  ]

  pageOutputTypeData = [
    { value: "png", caption: "Image" },
    { value: "pdf", caption: "Pdf" }
  ]
  jspdfPaper = {
    a0: [2383.94, 3370.39],
    a1: [1683.78, 2383.94],
    a2: [1190.55, 1683.78],
    a3: [841.89, 1190.55],
    a4: [595.28, 841.89],
    a5: [419.53, 595.28],
    a6: [297.64, 419.53],
    a7: [209.76, 297.64],
    a8: [147.4, 209.76],
    a9: [104.88, 147.4],
    a10: [73.7, 104.88],
    b0: [2834.65, 4008.19],
    b1: [2004.09, 2834.65],
    b2: [1417.32, 2004.09],
    b3: [1000.63, 1417.32],
    b4: [708.66, 1000.63],
    b5: [498.9, 708.66],
    b6: [354.33, 498.9],
    b7: [249.45, 354.33],
    b8: [175.75, 249.45],
    b9: [124.72, 175.75],
    b10: [87.87, 124.72],
    c0: [2599.37, 3676.54],
    c1: [1836.85, 2599.37],
    c2: [1298.27, 1836.85],
    c3: [918.43, 1298.27],
    c4: [649.13, 918.43],
    c5: [459.21, 649.13],
    c6: [323.15, 459.21],
    c7: [229.61, 323.15],
    c8: [161.57, 229.61],
    c9: [113.39, 161.57],
    c10: [79.37, 113.39],
    dl: [311.81, 623.62],
    letter: [612, 792],
    "government-letter": [576, 756],
    legal: [612, 1008],
    "junior-legal": [576, 360],
    ledger: [1224, 792],
    tabloid: [792, 1224],
    "credit-card": [153, 243]
  }
  pageSizeData: IPageSize[] = _.map(this.jspdfPaper, (p, k) => ({ value: k, caption: k, ratio: p[1] / p[0] }))

}
