import { animate, state, style, transition, trigger } from '@angular/animations';
import { CdkDragEnd, CdkDragMove } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { auditTime, map, Observable, of, startWith, Subject, take, tap, withLatestFrom } from 'rxjs';
import { SharedRepository } from 'src/app/shared/shared.repository';
import { IPrintParam, PrintParam } from '../../models/print-param';
import { PrintRepo } from '../../print.repository';
import { PrintService } from '../../services/print.service';
import  * as _  from "lodash";
import { faTshirt } from '@fortawesome/free-solid-svg-icons';
// import { movingPoint } from 'src/app/shared/components/dragger/dragger.component';

@Component({
  selector: 'app-print-canvas',
  templateUrl: './print-canvas.component.html',
  styleUrls: ['./print-canvas.component.scss'],
  animations: [
    trigger('upDown', [
      // ...
      state('up', style({
      })),
      state('down', style({
        transform: 'rotate(180deg)',
      })),
      transition('up => down', [
        animate('0.5s')
      ]),
      transition('down => up', [
        animate('0.5s')
      ]),
    ]),
  ]
})
export class PrintCanvasComponent implements OnInit ,AfterViewInit {

  @Input() size: 'normal' | 'medium' | 'small' = 'normal'

  @Input() readOnly: boolean = false

  @Input() printParamCustom :IPrintParam


  printParam$: Observable<IPrintParam> = this.ps.printParam$
    .pipe(map(a => { let p =  a as IPrintParam; p.sourceVerse = p.sourceVerse.replace('\n','<br/>'); return p;} ), startWith(new PrintParam()), tap(para => console.log({ para }))
    );

  canvaswidth = 0;

  constructor(private route: ActivatedRoute, private ps: PrintService, private pr: PrintRepo,
    private shr: SharedRepository) {

     
      
     }

     imageBaseUrl$  = this.shr.imageBaseUrl$;

     ngAfterViewInit(): void {
      // console.log({w:document.getElementById('mycanvas').style.width})
      // let h = document.getElementById('mycanvas').style.width
      // let ch = window.innerHeight * (Number(h.slice(0,2)))/100
      // console.log({ww:ch})
      
      // this.pr.updateTextWidth(Math.floor(ch * 0.5))

  }

  ngOnInit(): void {
    if(this.readOnly) this.printParam$ = of(this.printParamCustom)

  }

  isUpDown$ = this.ps.printParam$.pipe(map(p => p?.pagePosition))

  isHandset$ = this.shr.isHandset$.pipe(tap(hn=>console.log({hn})))

  getNgStylePageSize$(printParam: IPrintParam) {

    let whRatio = printParam.lookups.pageSizeData.find(a => a.value == printParam.pageSize)?.ratio as number


    return this.shr.isHandsetLandscape$.pipe(take(1),
      map(
        p => {
          p=false;
          let ret;
          if (this.size == 'normal') {
            ret = p ? {
              width: printParam.pageLandscape ? `${85 * whRatio}vh` : `${85 / whRatio}vh`,
              height: printParam.pageLandscape ? `85vh` : `85vh`,
            } : {
              width: printParam.pageLandscape ? "80vw" : `${75 / whRatio}vh`,
              height: printParam.pageLandscape ? `${80 / whRatio}vw` : `75vh`,
            }
          } else if (this.size == 'medium') {
            ret = p ? {
          
              width: printParam.pageLandscape ? `${85 * whRatio}vh` : `${85 / whRatio}vh`,
              height: printParam.pageLandscape ? `85vh` : `85vh`,
            }
              : {
                width: printParam.pageLandscape ? "50vw" : `${55 / whRatio}vh`,//'253px',// `${55 / whRatio}vh`,
                height: printParam.pageLandscape ? `${50 / whRatio}vw` :  `55vh`,//'366px', //' `55vh`,
              }
          } else {
            ret = {
              width: printParam.pageLandscape ? "80vw" : `${75 / whRatio}vh`,
              height: printParam.pageLandscape ? `${80 / whRatio}vw` : `75vh`,
            }
          }
          // console.log({w:document.getElementById('mycanvas').style.width})
          // let h = document.getElementById('mycanvas').style.width

          let ch =Math.floor( ret.width.slice(2,2)=='vh'? window.innerHeight * (Number(ret.width.slice(0,2)))/100*0.6:
          window.innerWidth * (Number(ret.width.slice(0,2)))/100*0.6);

          // console.log({ww:ch, orig:printParam.verseFormat.width,ret})
          if(printParam.verseFormat.width==0){
            printParam.verseFormat.width=ch;
            printParam.greetingFormat.width=ch;
          }

          // this.pr.updateTextWidth(Math.floor(ch))
          // console.log({pagesize:ret , printParam})
          return ret;//{width:'300px',height:'500px'}
        }
      ));


  }


  getNgStylePartB(printParam: IPrintParam) {

    // let pageTypeSize = printParam.pageType == 'flat' ? '100%' : '50%' //need to fix 50% , move up to parent
// console.log({stylePara:printParam})
    return {

      // top:String(printParam.verseFormat.MarginTop) + '% ',
      // left: printParam.verseFormat.MarginLeft + '%',
      padding: 0,
      width: '100%',
      height: '100%',
      // padding: String(printParam.verseFormat.MarginTop) + '% ' + printParam.verseFormat.MarginRight + '% ' +
      //   printParam.verseFormat.MarginBottom + '% ' + printParam.verseFormat.MarginLeft + '%',

      transform: printParam.pageSwitchSide && !printParam.pageLandscape ? 'rotate(180deg)' : 'none',
      order: printParam.pageSwitchSide ? '1' : '2',
      // ['max-height']: printParam.pageLandscape ? 'none' : pageTypeSize,
      // ['max-width']: printParam.pageLandscape ? pageTypeSize : 'none',
      color: printParam.verseFormat.textColor,

      'background-color':printParam.verseFormat.backgroundColor,
      

    }
  }

  getNgStylePartA(printParam: IPrintParam) {

    return {

      padding: 0,

      width: '100%',
      height: '100%',
      // padding: String(printParam.greetingFormat.MarginTop) + '% ' + printParam.greetingFormat.MarginRight + '% ' +
      //   printParam.greetingFormat.MarginBottom + '% ' + printParam.greetingFormat.MarginLeft + '%',
      transform: printParam.pageSwitchSide || printParam.pageLandscape ? 'none' : 'rotate(180deg)',
      order: printParam.pageSwitchSide ? '2' : '1',
      // ['max-height']: printParam.pageLandscape ? 'none' : '50%',
      // ['max-width']: printParam.pageLandscape ? '50%' : 'none',
      color: printParam.greetingFormat.textColor,
      'background-color':printParam.greetingFormat.backgroundColor,
       
    }
  }

  getNgStyleVersesPartB(printParam: IPrintParam) {
   
    // console.log({ngstyly:printParam.verseFormat.top})
    return {
      // padding: String(printParam.verseFormat.MarginTop) + '% ' + printParam.verseFormat.MarginRight + '% ' +
      //   printParam.verseFormat.MarginBottom + '% ' + printParam.verseFormat.MarginLeft + '%',

      top: `${printParam.verseFormat.top}px`,
      left: `${printParam.verseFormat.left}px`,
      width: `${printParam.verseFormat.width}px`,
      height: `${printParam.verseFormat.height}px`

    }
  }

  getNgStyleVersesPartA(printParam: IPrintParam) {

    return {
      // padding: String(printParam.greetingFormat.MarginTop) + '% ' + printParam.greetingFormat.MarginRight + '% ' +
      //   printParam.greetingFormat.MarginBottom + '% ' + printParam.greetingFormat.MarginLeft + '%',
      top: `${printParam.greetingFormat.top}px`,
      left: `${printParam.greetingFormat.left}px`,
      width: `${printParam.greetingFormat.width}px`,
      height: `${printParam.greetingFormat.height}px`
    }
  }

  getPartBBackgroundImageUrl(p: IPrintParam, imageBaseUrl:string) {
    return p.verseFormat.backgroundImgUrl ?? `${imageBaseUrl}/${p.verseFormat.backgroundImg}${p.verseFormat.backgroundImg.length<5?'.jpg':''}`

  }
  getPartABackgroundImageUrl(p: IPrintParam, imageBaseUrl:string) {
    return p.greetingFormat.backgroundImgUrl ?? `${imageBaseUrl}/${p.greetingFormat.backgroundImg}${p.greetingFormat.backgroundImg.length<5?'.jpg':''}`

  }
  
//   verseMoveStarted(p: PrintParam) {
//     this.dragStartPoint$.next(_.cloneDeep(p));
//   }

//   verseMoved(event: CdkDragMove,p:PrintParam) {
//     // console.log({  event:event.distance.y,p });
// this.dragMove$.next(event)
//     // p.verseFormat.top += event.distance.y;
//     // p.verseFormat.left += event.distance.x;

//     // this.process(p);
//   }

  process(p: any) {
    // console.log({ p });
    this.pr.updatePrintParam(p)

  }
  
  // replaceEnter(str:string){
  //   return str.replace(/\n/g,'<br>')
  // }

  // dragStartPoint$ = new Subject<PrintParam>();
  // dragMove$ = new Subject<CdkDragMove>();
  // dragEnd$ = new Subject<CdkDragEnd>();
  
  // dragMoveAutdited = this.dragMove$.pipe(
  //   withLatestFrom(this.dragStartPoint$),
  //   auditTime(16),
  //   tap(([{distance},p])=> 
  //   {
  //     // console.log({p:p.verseFormat.top,distance:distance.y})
  //      let x =  p.verseFormat.left + distance.x
  //      let y = p.verseFormat.top + distance.y
  //      let pa = _.cloneDeep(p)
  //      pa.verseFormat.left = x
  //      pa.verseFormat.top = y
  //     //  this.process(pa)
  //     //  console.log({p:p.verseFormat.top,distance:distance.y ,pa: pa.verseFormat.top })
  //     //  console.log({pa:pa.verseFormat.top, y})
  //   }
  //   )
  // ).subscribe()

//   dragEndaud =  this.dragEnd$.pipe(
// withLatestFrom(this.dragStartPoint$),
// auditTime(16),
// tap( ([{distance},p])=>{
//   let x =  p.verseFormat.left + distance.x
//   let y = p.verseFormat.top + distance.y
//   let pa = _.cloneDeep(p)
//   pa.verseFormat.left = x
//   pa.verseFormat.top = y
//   // this.process(pa)
//   // console.log({p:p.verseFormat.top,distance:distance.y ,pa: pa.verseFormat.top })

// } )

//   ).subscribe()

  // dragEnded($event: CdkDragEnd): void {
  //   // this.dragEnd$.next($event)
  //   // $event.source._dragRef.reset();
  //   // this.dragMove$.next($event)
    
    
  // }

  // myDragMove(e:movingPoint, p:PrintParam){
  //   // console.log({e,p})

  //    p.verseFormat.top= e.y
  //    p.verseFormat.left=e.x
  //   // this.process(p)

  // }

}
