import { Injectable } from '@angular/core';
import { Store, createState, withProps, select } from '@ngneat/elf';
import { IPrintParam, PrintParam } from './models/print-param';
import * as _ from "lodash";
import { filter } from 'rxjs/operators';




export interface PrintProps {
  printParam: IPrintParam | null
}


const { state, config } = createState(withProps<PrintProps>({
  printParam: new PrintParam()
  
}));

export const printStore = new Store({ name: 'print', state, config });



@Injectable({ providedIn: 'root' })
export class PrintRepo {

  printParam$ = printStore.pipe(select(st => st.printParam), filter(st => !_.isNull(st) && !_.isUndefined(st) && !_.isNaN(st)));
  printStore = printStore;
  snapShot = printStore.value

  updatePrintParam(p: IPrintParam) {
    let pp = _.assign({}, p)
    console.log({ updatePrintParam: pp })
    printStore.update(st => ({ ...st, printParam: pp }))

  }

  updateBackgroundImg(img:string,mode:string){
    let p = printStore.value.printParam
    
    if(mode=="verse"){
       p.verseFormat.backgroundImg = img;
       p.verseFormat.backgroundImgUrl=null;
       
    }else{
      p.greetingFormat.backgroundImg = img;
       p.greetingFormat.backgroundImgUrl=null;
    }
    
  }

  updateTextWidth(w:number){
    let p = this.snapShot.printParam;
    p.verseFormat.width = w;
    p.greetingFormat.width=w;
    printStore.update(st=>({...st, printParam: p }))
  }


}

