import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs';
import { FeedbackService } from '../../services/feedback.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {

  constructor(private fs:FeedbackService) { }

  ngOnInit() {}

  feedback$ = this.fs.feedback$.pipe(map((u:any)=>u.data ));

}
