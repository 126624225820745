import { Injectable } from '@angular/core';
import { map, mergeMap, tap } from 'rxjs/operators';
import { BackendService } from 'src/app/shared/services/backend.service';
import { SharedRepository } from 'src/app/shared/shared.repository';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  constructor(private shr:SharedRepository,private bs:BackendService) { }

  getTextBackImages$(category:string){
   return  this.shr.textBackgroundImages$.pipe(map(i=> i.filter(b=>b.category.toLowerCase() == category.toLowerCase()).map(bb=>bb.name)  ))
  }

  categories$ = this.shr.categoriesAdminUrl$.pipe(
    tap(uu=>console.log({uu})),
    mergeMap(u=> this.bs.get1<any>(u)),
    tap(uuu=>console.log({uuu})),
    )
}
