import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { of } from 'rxjs';
import { IPrintParam, PrintParam } from 'src/app/print/models/print-param';
import * as _ from "lodash";
import { SwiperComponent } from "swiper/angular";

// import Swiper core and required modules
// import SwiperCore, { EffectCards } from "swiper";
import SwiperCore, { EffectCube, Pagination ,Autoplay} from "swiper";

// install Swiper modules
// SwiperCore.use([EffectCards]);


SwiperCore.use([EffectCube, Pagination,Autoplay]);


// install Swiper modules
// SwiperCore.use([EffectCards]);

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class WelcomeComponent implements OnInit {

  printouts = _.range(1,19);

  constructor() { 

    let o
    for (let i = 1; i < 18; i++)
    {
      o = new PrintParam()
      o.verseFormat.backgroundImg=(100+i).toString()
      o.sourceVerse = 'Be completely humble and gentle; be patient, bearing with one another in love.'
      o.sourceIndex = 'eph 4:2'
      o.verseFormat.textColor= (i % 2) == 0? 'yellow':'red'
      o.verseFormat.width = 0
      o.verseFormat.left = 30
      this.printParamList.push(o)
    }
    
  }

  ngOnInit(): void {
    
  }

  printParamList:IPrintParam[] = []


}
