import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeatherService } from '../../services/feather.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {



  form: any = {
    username: null,
    password: null
  };
  isLoggedIn = false;
  isLoginFailed = false;
  errorMessage = '';
  roles: string[] = [];

  message = '';

  data: {} = {};

  messages: string[] = [];

  constructor(private feathers: FeatherService, private router: Router) {

  }

  onSubmit(): void {
    const { username, password } = this.form;
    this.errorMessage = '';
    if (!username || !password) {
      this.errorMessage = 'Incomplete credentials!';
      return;
    }

    // try to authenticate with feathers
    this.feathers.authenticate({
      strategy: 'local',
      email: username,
      password
    })
      // navigate to base URL on success
      .then((a: any) => {
        console.log({ authlogin: a })
        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.router.navigate(['/welcome']);
      })
      .catch(err => {
        console.log({ err })
        this.errorMessage = err['message'];
        this.isLoginFailed = true;

        //this.messages.unshift('Wrong credentials!');
      });

  }
  resetPwd() {
    //reset password
    console.log("reset password");
    this.errorMessage = '';
    const { username, password } = this.form;


    if (!username) {
      this.errorMessage = 'please provide email address!';
      return;
    }
    this.feathers.service('api/auth-management').create({
      action: 'sendResetPwd',
      value: { email: username }
    }).then((res: any) => {
      console.log({ res })
      this.message = 'reset password email sent!';
    }).catch((err: any) => {
      console.log({ err })
      this.errorMessage = 'reset password email failed!';
    });

    // // try to authenticate with feathers
    // this.feathers.authenticate({
    //   strategy: 'local',
    //   email:username,
    //   password
    // })
    //   // navigate to base URL on success
    //   .then(() => {
    //     this.isLoginFailed = false;
    //     this.isLoggedIn = true;
    //     this.router.navigate(['/home']);
    //   })
    //   .catch(err => {
    //     this.errorMessage = err['message'];
    //     this.isLoginFailed = true;
    //     this.messages.unshift('Wrong credentials!');
    //   });

  }




  login() {
    const { username, password } = this.form;
    if (!username || !password) {
      this.errorMessage = 'Incomplete credentials!';
      return;
    }

    // try to authenticate with feathers
    this.feathers.authenticate({
      strategy: 'local',
      email: username,
      password
    })
      // navigate to base URL on success
      .then(() => {
        this.isLoginFailed = false;
        this.isLoggedIn = true;
        this.router.navigate(['/welcome']);
      })
      .catch(err => {
        this.errorMessage = err['message'];
        this.isLoginFailed = true;
       // this.messages.unshift('Wrong credentials!');
      });
  }



  signup(email: string, password: string) {
    this.feathers.service('api/users')
      .create({ email, password, name: password })
      .then(() => this.messages.push('User created.'))
      .catch((_: any) => this.messages.push('Could not create user!'))
      ;
  }
  logout() {
    this.feathers.logout();
  }
  getHist() {
    this.feathers.service("api/history").find({}).then((data: any) => this.data = data);
  }
  ngOnInit(): void {
  }

}
