import { Component, OnInit } from '@angular/core';
import { BooksService } from '../../services/books.service';

@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.scss'],
})
export class BooksComponent implements OnInit {

  constructor(private bs:BooksService ) { }
  OTBooks$ = this.bs.OT$;

  NTBooks$ = this.bs.NT$;

  otTitle$ = this.bs.otTtile$;
  ntTitle$ = this.bs.ntTtile$
  
  ngOnInit() {}

}
