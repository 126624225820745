import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { NavComponent } from './components/nav/nav.component';



import { IonicModule } from '@ionic/angular';
import {SharedModule  } from "../shared/shared.module";
import { RouterModule  } from "@angular/router";
import { SwiperModule } from "swiper/angular";
import { PrintModule } from "../print/print.module";
import { HelpComponent } from './components/help/help.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { TouComponent } from './components/tou/tou.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { QaComponent } from './components/qa/qa.component';
import {YouTubePlayerModule} from '@angular/youtube-player';

@NgModule({
  declarations: [WelcomeComponent, NavComponent, HelpComponent, PrivacyComponent, TouComponent, AboutComponent, ContactComponent, QaComponent],
  imports: [
    CommonModule,
      
    IonicModule.forRoot(),
    SharedModule,
    RouterModule,
    PrintModule,
    SwiperModule,
    YouTubePlayerModule
  ],
  exports:[
    NavComponent,
    WelcomeComponent
  ]
})
export class CoreModule { }
