import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/shared/services/backend.service';
import { SharedRepository } from 'src/app/shared/shared.repository';


@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(private bs: BackendService, private shr: SharedRepository) { }

  feedback$ = this.bs.get$(this.shr.feedbackApiPath$);
}
