import { A, D, K } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, startWith, take, tap, withLatestFrom } from 'rxjs/operators';
// import { EventBusService, Events } from "../../core/services/event-bus.service";
import * as _ from "lodash";

import { IPrintParam, PrintParam } from "../../models/print-param";
import { PrintService } from '../../services/print.service';
import {
  trigger,
  state,
  style,
  animate,
  transition,

} from '@angular/animations';
// import { ResizeEvent } from 'angular-resizable-element';
import { PrintRepo } from '../../print.repository';
import { select } from '@ngneat/elf';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedRepository } from 'src/app/shared/shared.repository';


@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss'],
  animations: [
    trigger('upDown', [
      // ...
      state('up', style({
      })),
      state('down', style({
        transform: 'rotate(180deg)',
      })),
      transition('up => down', [
        animate('0.5s')
      ]),
      transition('down => up', [
        animate('0.5s')
      ]),
    ]),
  ]
})
export class PrintComponent implements OnInit {

  // printParam$: Observable<IPrintParam> = this.ps.printParam$
  //   .pipe(map(a => a as IPrintParam), startWith(new PrintParam()), tap(para => console.log({ para })));

  constructor(private route: ActivatedRoute, private ps: PrintService, private pr: PrintRepo,
    private shr: SharedRepository) {

    route.queryParams.subscribe(p => {
      console.log({ p })
      if (p['share']) {

        ps.processSharedUrl(p['share'])
      }
    })

  }
  
  isEsvMain$ = this.shr.isEsvMain$
  strEsvCopyright$ = this.shr.strEsvCopyright$

  // isHandset$ = this.shr.isHandset$

  ngOnInit(): void { }

  // isUpDown$ = this.ps.printParam$.pipe(map(p => p?.pagePosition))


  // getNgStylePageSize$(printParam: IPrintParam) {

  //   let whRatio = printParam.lookups.pageSizeData.find(a => a.value == printParam.pageSize)?.ratio as number


  //   return this.shr.isHandsetLandscape$.pipe(take(1),
  //   map(
  //     p => {
  //       let ret = p ? {
  //         width: printParam.pageLandscape ? `${85 * whRatio}vh` : `${85 / whRatio}vh`,
  //         height: printParam.pageLandscape ? `85vh` : `85vh`,  
  //       } : {
  //         width: printParam.pageLandscape ? "80vw" : `${75 / whRatio}vh`,
  //         height: printParam.pageLandscape ? `${80 / whRatio}vw` : `75vh`,
  //       }
  //       // console.log({pagesize:ret , printParam})
  //       return ret;//{width:'300px',height:'500px'}
  //     }
  //   ));

  // }


  // getNgStylePartB(printParam: IPrintParam) {

  //   let pageTypeSize = printParam.pageType == 'flat' ? '100%' : '50%'

  //   return {
  //     padding: String(printParam.verseFormat.MarginTop) + '% ' + printParam.verseFormat.MarginRight + '% ' +
  //       printParam.verseFormat.MarginBottom + '% ' + printParam.verseFormat.MarginLeft + '%',
  //     transform: printParam.pageSwitchSide && !printParam.pageLandscape ? 'rotate(180deg)' : 'none',
  //     order: printParam.pageSwitchSide ? '1' : '2',
  //     ['max-height']: printParam.pageLandscape ? 'none' : pageTypeSize,
  //     ['max-width']: printParam.pageLandscape ? pageTypeSize : 'none',
  //     color: printParam.verseFormat.textColor

  //   }
  // }

  // getNgStylePartA(printParam: IPrintParam) {

  //   return {
  //     padding: String(printParam.greetingFormat.MarginTop) + '% ' + printParam.greetingFormat.MarginRight + '% ' +
  //       printParam.greetingFormat.MarginBottom + '% ' + printParam.greetingFormat.MarginLeft + '%',
  //     transform: printParam.pageSwitchSide || printParam.pageLandscape ? 'none' : 'rotate(180deg)',
  //     order: printParam.pageSwitchSide ? '2' : '1',
  //     ['max-height']: printParam.pageLandscape ? 'none' : '50%',
  //     ['max-width']: printParam.pageLandscape ? '50%' : 'none',
  //     color: printParam.greetingFormat.textColor
  //   }
  // }

  // getNgStyleVersesPartB(printParam: IPrintParam) {

  //   return {
  //     padding: String(printParam.verseFormat.MarginTop) + '% ' + printParam.verseFormat.MarginRight + '% ' +
  //       printParam.verseFormat.MarginBottom + '% ' + printParam.verseFormat.MarginLeft + '%',

  //   }
  // }

  // getNgStyleVersesPartA(printParam: IPrintParam) {

  //   return {
  //     padding: String(printParam.greetingFormat.MarginTop) + '% ' + printParam.greetingFormat.MarginRight + '% ' +
  //       printParam.greetingFormat.MarginBottom + '% ' + printParam.greetingFormat.MarginLeft + '%',

  //   }
  // }

  // getPartBBackgroundImageUrl(p:IPrintParam){
  //   return p.verseFormat.backgroundImgUrl ?? `${this.shr.imageBaseUrl$}/${p.backgroundImg}.jpg`

  // }

  // { 
  //   'margin': printParam.verseFormat.MarginTop+'% '; printParam.verseFormat.MarginRight+'% '; 
  //   printParam.verseFormat.MarginBottom+'% '+ printParam.verseFormat.MarginLeft+'%' }" 
  //   [ngClass]="{'textOriV': printParam.verseFormat.textOrientationV,'textOriH': !printParam.verseFormat.textOrientationV}



  // isOpen = true;
  // toggle() {
  //   this.isOpen = !this.isOpen;
  // }




  // public style: object = {};
  // validate(event: ResizeEvent): boolean {
  //   const MIN_DIMENSIONS_PX: number = 50;
  //   if (
  //     event.rectangle.width &&
  //     event.rectangle.height &&
  //     (event.rectangle.width < MIN_DIMENSIONS_PX ||
  //       event.rectangle.height < MIN_DIMENSIONS_PX)
  //   ) {
  //     return false;
  //   }
  //   return true;
  // }

  // onResizeEnd(event: ResizeEvent): void {
  //   this.style = {
  //     position: 'fixed',
  //     left: `${event.rectangle.left}px`,
  //     top: `${event.rectangle.top}px`,
  //     width: `${event.rectangle.width}px`,
  //     height: `${event.rectangle.height}px`,
  //   };
  // }

  // showBtn = false;
  // toggleBtn(){
  //   this.showBtn = !this.showBtn
  //   console.log({flagmove:this.showBtn})
  // }

}
