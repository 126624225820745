import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, EMPTY, mergeMap } from 'rxjs';
import { FeatherService } from '../../services/feather.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  emailFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  passwordFormControl = new UntypedFormControl('', [Validators.required, Validators.minLength(1)]);
  passwordFormControl1 = new UntypedFormControl('', [Validators.required, Validators.minLength(1)]);
  
  pwd:string ="";
  message = new BehaviorSubject('');
  message$ = this.message.asObservable();

  successMessage() {
    return 'Your password has been reset successfully.';
  }

  errorMessage() {
    return 'Your password could not be reset. please try again.';
  }

  

  constructor(private fs:FeatherService,private route: ActivatedRoute) {

  
    }

  resetPassword(){
    this.pwd = this.passwordFormControl.value;

    console.log("pwd",this.pwd);
    this.route.queryParams
    // .pipe(mergeMap(p => this.fs.verifySignup$(p.token)))
    // .subscribe(
    //   () => this.success.next(true),
    //   () => this.success.next(false)
    // );
  .pipe( mergeMap( p => {
    if (  p.token != null ) {
      console.log("token",p.token);
   
      return this.fs.create('api/auth-management',{ action:'resetPwdLong', value: {token:p.token,password:this.pwd},notifierOptions:{} })
      }
      else {
        return null;
      }
  
  })).subscribe(
    {
     next:(res:any)=>{
       this.message.next(this.successMessage());
       console.log({res  })
     },
     error:(err:any)=>{
       this.message.next(this.errorMessage());
       console.log({err})
     }

    }
   ) ;
  }


  ngOnInit() {}

}
