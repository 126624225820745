import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SharedRepository } from 'src/app/shared/shared.repository';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {

  constructor(private searchser:SearchService,private router: Router,private shr:SharedRepository) { }

  ngOnInit() {}

  isEsvMain$ = this.shr.isEsvMain$

  

  searchKey$ = this.searchser.searchKey$;

  search$ = this.searchser.search$().pipe(tap(console.log))
  isSearchReturn$ = this.search$.pipe(map(a => a.length > 0))
  
  isShowCopyright$ = combineLatest([this.isEsvMain$, this.isSearchReturn$]).pipe(map(([a, b]) => a && b))
  strEsvCopyright$ = this.shr.strEsvCopyright$

  onLinkClick(row: any) {
    console.log({ row })
    this.router.navigate([{ outlets: { primary: ['chapter', row.book, row.chapter, row.verse], toolbarLeft: ['chapter', row.book, row.chapter, row.verse], toolbarRight: null } }]);
  }

}
