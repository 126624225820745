import { Injectable } from '@angular/core';

import { combineLatest } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { SharedRepository } from 'src/app/shared/shared.repository';

@Injectable({
  providedIn: 'root'
})
export class BooksService {

  constructor(private sr : SharedRepository) { }

  OT$= combineLatest([this.sr.versions$,this.sr.versionSetting$])
  .pipe(map(([v,vs]) =>{

    var main = vs.find( v=>v.main)?.code;
    return v?.find(vr=> vr.code == main )?.content?.slice(0,39)

  }));
  

  NT$ =combineLatest([this.sr.versions$,this.sr.versionSetting$])
  .pipe(map(([v,vs]) =>{

    var main = vs.find( v=>v.main)?.code;
    return v?.find(vr=> vr.code == main )?.content?.slice(39)

  }));

  ntTtile$ = this.sr.versionSetting$.pipe(map(v=>  {
    return ['cus','cut'].includes(v.find(m=>m.main)?.code)? '新约':"New Testament"
  }     ))

  otTtile$ = this.sr.versionSetting$.pipe(map(v=>  {
   return  ['cus','cut'].includes(v.find(m=>m.main)?.code)? "旧约":"Old Testament";
  }     ))

  
  
}
