import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { BackendService } from 'src/app/shared/services/backend.service';
import { FeatherService } from 'src/app/shared/services/feather.service';
import { SharedRepository } from 'src/app/shared/shared.repository';

@Injectable({
  providedIn: 'root'
})
export class ImagesService {

  constructor(private shr: SharedRepository, private bs: BackendService,
    private fs : FeatherService) { }
  categories$ = this.shr.categoriesAdminUrl$.pipe(
    tap(uu => console.log({ uu })),
    mergeMap(u => this.bs.get1<any>(u)),
    tap(uuu => console.log({ uuu })),
  );

  getImages$(category: string) {
    console.log({image:'called'})
    return this.shr.images$
    .pipe(
      tap(ii=>console.log({ii})),
      withLatestFrom(this.shr.imageBaseUrl$),
      map(([i,p]) => {
      var f= i.filter(b => category == null || b.category.toLowerCase() == category.toLowerCase())
      return f.map(i=> ({...i, 
        listItem:{src:i.name=='999'?'': i.name.length <5 ? `${p}/${i.name}.jpg`
        :`${p}/${i.name}`,caption:i.name,code:i.name }}))
    
    }
      
      )
      )
  }

  updateImage(img:any)  {
    this.shr.imageAdminPath$.pipe(
      mergeMap(url=>this.fs.update(url, img._id, img))
    ).subscribe(r=>console.log({r}))

    // this.fs.service('api/mongo-image').update(img._id,img).then(r=>console.log({r}));

    // this.shr.imageAdminUrl$.pipe(
    //   tap(uu=>console.log({uu})),
    //   map(u=> this.bs.put(u+"/"+img._id,img)),
    //   // tap(uuu=>console.log({uuu})),
    //   ).subscribe()

  }
  deleteImage(img:any)  {
    console.log({img})
    this.shr.imageAdminPath$.pipe(
      mergeMap(url=>this.fs.remove(url, img._id))
    ).subscribe(r=>console.log({r}))

  }

  addImage(img:any)  {
    this.shr.imageUploadPath$.pipe(
      mergeMap(url=>this.fs.create(url,img))
    ).subscribe(r=>console.log({r}))
    

    // this.shr.imageUploadUrl$.pipe(
    //   tap(uu=>console.log({uu})),
    //   tap(u=> this.bs.post(u,img)),
    //   // tap(uuu=>console.log({uuu})),
    //   ).subscribe()

  }
  
}
