import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { map } from 'rxjs';
import { ImageListItem } from 'src/app/shared/models/image-list-item';
import { SettingService } from 'src/app/shared/services/setting.service';
import { ImagesService } from '../../services/images.service';
import { ImageAddComponent } from '../image-add/image-add.component';
import { ImageEditComponent } from '../image-edit/image-edit.component';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss'],
})
export class ImagesComponent implements OnInit {

  constructor(private ims: ImagesService, private dialog: MatDialog, private ss:SettingService) { 

    dialog.afterAllClosed.subscribe(
      ()=> { console.log('dialog Refresh..');  ss.initImagesLoad()}
    )
  }

  ngOnInit() { }

  categories$ = this.ims.categories$;

  imgs$ = this.ims.getImages$(null).pipe(map(i => _.map(i, ii => ii.listItem)))



  imageClick(img: ImageListItem) {
    console.log({ img })
    this.dialog.open(ImageEditComponent, {
      panelClass: 'shareDialogPanel',
      width: '300px',
      height: '310px',
      // position: { top: '50px', right: '50px' },
      data: {
        img: img
      },
    });
    

  }

  addImg(img:any){
   
    console.log({ img })

    this.dialog.open(ImageAddComponent, {
      panelClass: 'shareDialogPanel',
      width: '500px',
      height: '510px',
      // position: { top: '50px', right: '50px' },
      data: {
        img: img
      },
    });

   
  }

}
