import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { HttpEventType, HttpClient } from '@angular/common/http';
import { SettingService } from '../../services/setting.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {


  public progress: number;
  public message: string;
  @Output() public onUploadFinished = new EventEmitter();

  constructor(private http: HttpClient,private ss:SettingService) { }
  ngOnInit() {
  }

  public uploadFile = (files:any) => {
    if (files.length === 0) {
      return;
    }

    let fileToUpload = <File>files[0];


    console.log({files,fileToUpload})
    
    this.onUploadFinished.emit(fileToUpload);

    // const formData = new FormData();
    // formData.append('file', fileToUpload, fileToUpload.name);
    // this.http.post('http://localhost:5000/api/upload', formData, { reportProgress: true, observe: 'events' })
    //   .subscribe(event => {
    //     if (event.type === HttpEventType.UploadProgress)
    //       this.progress = Math.round(100 * event.loaded / Number(event.total));
    //     else if (event.type === HttpEventType.Response) {
    //       this.message = 'Upload success.';
    //       this.onUploadFinished.emit(event.body);
    //     }
    //   });
  }


  updateAppbackgroundCustomized(b:string){

    let r = document.documentElement;
    let sty = getComputedStyle(r)
    r.style.setProperty('--backGroundImg', `url('${b}')`)
    
    // this.sr.updateAppbackground(b);
  }


}