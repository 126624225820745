import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, interval, Subject, Subscription } from 'rxjs';
import { map, startWith, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { SharedRepository } from 'src/app/shared/shared.repository';
import { IPrintParam } from "../models/print-param";
// import { IPrintParam } from '.models/print-param';
// import { GenParam  } from "./print-toolbar/print-toolbar.component";
import { PrintRepo } from '../print.repository';
import *  as _ from "lodash";
import { PrintSnackbarComponent } from '../components/print-snackbar/print-snackbar.component';
import { jsPDF } from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UtilService } from 'src/app/shared/services/util.service';
import { NumberToChineseWords as converter } from "number-to-chinese-words";
import * as  download  from "downloadjs";

@Injectable({
  providedIn: 'root'
})
export class PrintService {
    
  
  constructor(private pr: PrintRepo,private shr:SharedRepository, private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,
    private us:UtilService) {

    // subscribe to content from bible and star
    shr.printContent$
      .pipe(
        withLatestFrom(this.printParam$),
        map(([cont, pp]) => {
          let p = pp as IPrintParam
          console.log({ cont, p })
          let grouped = 
          _.chain(cont)
          .groupBy(a => a.bookName)
          // .tap(z => console.log({ z }))
            .map((b, c) => {
              let chapterCont = 
              _.chain(b)
              .groupBy(d => d.chapterId)
              .map((e, f) => {
                
                // console.log({  converter})
                  let ver = e[0].version;
                  let indexT = ["cus", "cut"].includes(ver) ?
                    converter.toWords(Number(f))  + '章 ' + e.map(g => g.verseId).sort(l => l).map(i=>  converter.toWords(i)).join(',') + '节'
                    : 'Chapter ' + f + ' Verse ' + e.map(g => g.verseId).sort(l => l).join(',');
                  // console.log({ e, f, ver, indexT })
                  return {
                    //// npm convert number to chinese characters
                    i: indexT,
                    t: e.map(h => h.verseT).join(''),
                    v: ver
                  }
                }
                );
              return ({
                ind: c + ' ' + chapterCont.map(j => j.i).join(',').value(),
                text: chapterCont.map(k => k.t).join('<br>').value(),
                v: chapterCont.map(k => k.v).value()
              })
            });
          let v1 = grouped.map(a => a.v).first().value()[0];
          p.verseFormat.textOrientationV = ["cus", "cut"].includes(v1) ? p.verseFormat.textOrientationV : false;
          p.sourceVerse = grouped.map(a => a.text).join('<br>').value();
          p.sourceIndex = `${grouped.map(j => j.ind).join(',').value()}, ${_.upperCase(v1)}`;
          // console.log({ parampri:p });
          // this.printParam.next(p);
          pr.updatePrintParam(p)
          // pr.printStore.update(st => ({ ...st, printParam: p }));
        })//,
        // takeUntil(this.end$) ??? singleton, so not end to this subscription

      ).subscribe()
   }

   processSharedUrl(sharedUrl:string){
      
      let jObj = this.us.getJsonFromUrl(sharedUrl) as IPrintParam
      let param = _.assign(_.assign({}, this.pr.snapShot.printParam),jObj)
      this.pr.updatePrintParam(param)


   }

  
  printParam$= this.pr.printParam$;

  exporting = new BehaviorSubject(false);
  exporting$  = this.exporting.asObservable()

  callImgGenerator(e: HTMLElement, pageOrientation: 'l' | 'p', pageSize: string) {
    // this.ps.printPdf({ e, pageOrientation, pageSize })
    console.log({ e, pdfa: Date() });
    // return 0
    this.exporting.next(true)
    htmlToImage
      .toJpeg(e, {
         backgroundColor: 'white',

        //refactor page size according to param
        // canvasHeight:419,
        // canvasWidth:297
      })
      .then((dataUrl) => {
        console.log({ pdfb: Date(), dataurl: dataUrl });

        var link = document.createElement('a');
        link.download = 'myPrint.jpeg';
        link.href = dataUrl;
        link.click();
        this.exporting.next(false)
        this.generationComplete();
        return;


  });

}


  callGenerator(e: HTMLElement, pageOrientation: 'l' | 'p', pageSize: string, outputType: 'pdf' | 'png',p:IPrintParam) {
    // this.ps.printPdf({ e, pageOrientation, pageSize })
    // console.log({ e, pdfa: Date() });
    // return 0
    let w = p.lookups.jspdfPaper[pageSize][0];
    let h = p.lookups.jspdfPaper[pageSize][1];

    if(pageOrientation=='l'){
      w = p.lookups.jspdfPaper[pageSize][1];
      h = p.lookups.jspdfPaper[pageSize][0];
    }
    
    this.exporting.next(true)
    htmlToImage
      .toPng(e, {
        backgroundColor: 'white',
         canvasHeight:h,
         canvasWidth:w
        
      })
      .then((dataUrl) => {
        // console.log({ pdfb: Date(), dataurl: dataUrl });
        if (outputType == 'png') {
          download(dataUrl,'myBiblePrint.png');
          console.log('png created...');
          this.exporting.next(false)
          this.generationComplete();
        }
        else {
      
          var img = new Image();
          img.src = dataUrl;
          //  document.body.appendChild(img);
  
          let pdf = new jsPDF(pageOrientation, 'px', pageSize);
          console.log('jspdf created...');
          const bufferX = 0;
          const bufferY = 0;//5;
          const imgProps = pdf.getImageProperties(img);
  
          const pdfW = pdf.internal.pageSize.getWidth() - 2 * bufferX;
  
          const pdfH = (imgProps.height * pdfW) / imgProps.width;
  
          pdf.addImage(
            img,
            'PNG',
            bufferX,
            bufferY,
            pdfW,
            pdfH,
            undefined,
            'NONE'
          );
  
          console.log('pdf add image...');
  
          pdf.save('myBiblePrint.pdf');
          this.exporting.next(false)
  
          console.log('pdf saveed...');
          console.log({ pdfc: Date() });
          this.generationComplete();
  
        }
        
        

      });
  }

  generationComplete() {

    this.snackBar.openFromComponent(PrintSnackbarComponent, {
      duration: 2000,
      panelClass: ['greenSnackBar'],
    });
  }

  exportPdf() {
    // let e = document.getElementById("print-section") as HTMLElement;//?.cloneNode(true);
    let e = document.getElementById('print-section') as HTMLElement; //?.cloneNode(true);

    console.log('before png created...');
  
    // this.callGenerator(e, 'l', 'a4');

    // const fontEmbedCss =  htmlToImage.getFontEmbedCSS(e);

    this.printParam$
      .pipe(
        take(1),
        map((param) => {
          let p = param as IPrintParam;
    
          let pageOrientation: 'l' | 'p' = p.pageLandscape ? 'l' : 'p';
          let pageSize = p.pageSize;
          let outputType = p.pageOutputType;
          console.log('before png created...');
          console.log({pageOrientation,pageSize});
          this.callGenerator(e, pageOrientation, pageSize,outputType,p);
          // this.callImgGenerator(e, pageOrientation, pageSize);
          return "completed"
        })
      ).subscribe()
  }

  setPartBBackgroundImage(file:File,printParam:IPrintParam){
    // var selectedFile = event.target.files[0];
    var reader = new FileReader();
    let pr = this.pr;

    reader.onload = function(event) {
      // this.updateAppbackgroundCustomized("")    
      let src = event.target?.result;
      
    printParam.verseFormat.backgroundImgUrl= src as string;
    console.log({src,paramUrl: printParam.verseFormat.backgroundImgUrl})
    pr.updatePrintParam(printParam)
    
    };
  
    reader.readAsDataURL(file);
}

setPartABackgroundImage(file:File,printParam:IPrintParam){
  // var selectedFile = event.target.files[0];
  var reader = new FileReader();
  let pr = this.pr;

  reader.onload = function(event) {
    // this.updateAppbackgroundCustomized("")    
    let src = event.target?.result;
    
  printParam.greetingFormat.backgroundImgUrl= src as string;
  console.log({src,paramUrl: printParam.greetingFormat.backgroundImgUrl})
  pr.updatePrintParam(printParam)
  
  };

  reader.readAsDataURL(file);
}


  /**
   * 
   * @param data 
   * 
   * generate pdf in web worker suspended
   */
  // printPdf(data: GenParam){

  //   console.log({data})
  //   if (typeof Worker !== 'undefined') {
  //     // Create a new
  //     const worker = new Worker(new URL('./print.worker', import.meta.url));
  //     worker.onmessage = ({ data }) => {
  //       console.log(`page got message: ${data}`);
  //     };
  //     worker.postMessage('this is the request');
  //   } else {
  //     // Web Workers are not supported in this environment.
  //     // You should add a fallback so that your program still executes correctly.
  //   };

  // }

  // process parameters

  counter = interval(100);
  sub: Subscription;
  
  startCounter(prop: string, inc: boolean) {
    let props = prop.split(',');

    this.sub = this.counter.pipe(startWith(1), withLatestFrom(this.printParam$))
      .subscribe(([c, p]) => {
        // console.log({ c, p, prop });

        props.forEach(propT => {
          if (inc) { _.set(p as any, propT, _.get(p as any, propT) + 1); }
          else {
            _.set(p as any, propT, _.get(p as any, propT) - 1);
          }
        });
        // this.printParam.next(p);
        this.process(p);
        // console.log({ c, p, prop, ppp: (_.get(p as any, prop)) });
      });

    // setInterval(function () { l; console.log({ a, b, c }); }, 1000);
    // console.log({ a, b, c });
  }

  stopCounter() {
    // clearInterval();
    if (this.sub) {
      this.sub.unsubscribe();
    }
    // console.log('stopped...');
  }
  process(p: any) {
    // console.log({ p });
   this.pr.updatePrintParam(p)
   
 }

  
}
