import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BooksComponent } from './components/books/books.component';
import { ChapterComponent } from './components/chapter/chapter.component';
import { MaterialModule } from '../shared/material/material.module';
import { BookComponent } from './components/book/book.component';

import { RouterModule } from "@angular/router";
import { SearchComponent } from './components/search/search.component';
import { SearchToolbarComponent } from './components/search-toolbar/search-toolbar.component';
import { StarComponent } from './components/star/star.component';
import { SharedModule } from '../shared/shared.module';
import { BibleToolbarComponent } from './components/bible-toolbar/bible-toolbar.component';
import { HistoryComponent } from './components/history/history.component';

@NgModule({
  declarations: [BooksComponent,
    ChapterComponent,
    BookComponent,
    SearchComponent,
    SearchToolbarComponent,
    StarComponent,
    BibleToolbarComponent, 
    HistoryComponent],
  imports: [
    CommonModule,
    MaterialModule
    , RouterModule,
    SharedModule

  ]
})
export class BibleModule { }
