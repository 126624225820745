import { Component, Inject, OnInit } from '@angular/core';
import { UtilService } from '../../services/util.service';
import { MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
export interface DialogData{
  linkUrl:string,
  JsonParam: {}
}

@Component({
  selector: 'app-share-link-dialog',
  templateUrl: './share-link-dialog.component.html',
  styleUrls: ['./share-link-dialog.component.scss']
})
export class ShareLinkDialogComponent implements OnInit {

  urlToShare:string;

  constructor(private us:UtilService, @Inject(MAT_DIALOG_DATA) public data: DialogData,
  public dialogRef: MatDialogRef<ShareLinkDialogComponent>,
   ) { 
    this.urlToShare = data.linkUrl+ '?share='+ us.getUrlFromJson(data.JsonParam)
  }

  ngOnInit(): void {


  }

  closeDialog(){
    this.dialogRef.close();
  }

}
