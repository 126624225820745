import { Component, OnInit } from '@angular/core';


import { fromEvent, interval, map, startWith, take, tap, withLatestFrom } from 'rxjs';
import { SharedRepository } from 'src/app/shared/shared.repository';

@Component({
  selector: 'app-qa',
  templateUrl: './qa.component.html',
  styleUrls: ['./qa.component.scss'],
})
export class QaComponent implements OnInit {

  apiLoaded = false;
  constructor( private shr: SharedRepository) { }
  

  width$ = fromEvent(window, 'resize')
  .pipe(startWith({target:{innerWidth:window.innerWidth-100}}), 
    map((e: any) => e.target.innerWidth-100),
    withLatestFrom(this.shr.isHandset$),map(([width,isHandset])=>isHandset?width+90:width));
  

  ngOnInit() {
    if (!this.apiLoaded) {
      // This code loads the IFrame Player API code asynchronously, according to the instructions at
      // https://developers.google.com/youtube/iframe_api_reference#Getting_Started
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      document.body.appendChild(tag);
      this.apiLoaded = true;
    }
  }

}
