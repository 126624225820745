import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, tap, withLatestFrom } from 'rxjs';
import { BookService } from '../../services/book.service';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss'],
})
export class BookComponent implements OnInit {

  constructor(private bs: BookService, private route: ActivatedRoute, private router:Router) { }

  chapters$ = this.route.params
    .pipe(mergeMap(p => this.bs.bookChapters$(p['book'])),
      map(c => Array.from({ length: c.chapters }, (_, i) => i + 1).map(ch => ({ book: c.book, chapter: ch })))
      );
  bookName$ = this.route.params
    .pipe(mergeMap(p => this.bs.bookName$(p['book'])));

  ngOnInit() { }

  
  onLinkClick(chapter: any) {
    console.log({ chapter })
    this.router.navigate([{ outlets: { primary: ['chapter', chapter.book, chapter.chapter], toolbarLeft: ['chapter', chapter.book, chapter.chapter], toolbarRight: null } }]);
  }


}
