import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from "lodash";

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  constructor(private s: DomSanitizer) { }

  transform(value: any, args: any): any {
    

    if (_.isNull(value) || _.isUndefined(value) || _.isEmpty(value) || _.isUndefined(args) || _.isEmpty(args)) {
      return value
    }
    else
    if (args == null || _.isUndefined(args) || _.isEmpty(args)) {
      return value ;
    } 
    let keys = (args as string).split('|');

    
    keys.forEach(a => {
      let ind = (value as string).toLowerCase().indexOf(a.toLowerCase());
      // console.log({ind})
      if (ind > -1) {
        let e = new RegExp( (a),'gi')
        value = (value as string).replace(e,  '<span style="background-color:yellow;">$&</span>')
        //  console.log({value});
      }
    });

    //  console.log({value});
    return this.s.bypassSecurityTrustHtml(value);
  }
}
