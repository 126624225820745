import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import {  map, mergeMap, take, tap } from 'rxjs/operators';
import { BackendService } from 'src/app/shared/services/backend.service';
import { SharedRepository } from 'src/app/shared/shared.repository';
import { HistoryRepository } from '../history.repository';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(private sr: SharedRepository,
    private bes: BackendService,
    private hr:HistoryRepository) {

      
     }

  history$ = this.hr.history$.pipe(tap(h=>console.log({h})))

  saveHistoryLog(content: any) {
    this.hr.addHistory({...content,date: new Date(), _id: Date.now()})

    this.sr.historyUrl$.pipe(
      take(1),
      // map(a => a + "/log"), 
      tap(
        u => this.bes.post(u, { user: 'temp', log: content,date: new Date() })
      ),
      // tap(b => this.eb.set(Events.History, (b as History).log)),
      // tap(a => console.log("savedhistory")),
      tap(a => console.log({ savedhistory: content }))
    ).subscribe()

    // this.br.addHistoryLog(content);
  }
  bookNames$ = combineLatest([this.sr.versions$,this.sr.main$]).pipe(
    map(([v,m])=> v.find(vv=>vv.code==m.code).content )
  );

  getHistory$() {
    return   combineLatest([this.sr.historyUrl$,this.sr.versions$]).pipe(
      take(1),
      mergeMap(([hu,v])=>{
        return this.bes.get<any>(hu)
      })
    )
  }

}
