import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { FeatherService } from 'src/app/shared/services/feather.service';
import { ContactService } from '../../services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  emailFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  nameFormControl = new UntypedFormControl('', [Validators.required, Validators.minLength(1)]);
  messageFormControl = new UntypedFormControl('', [Validators.required, Validators.minLength(1)]);

  constructor(private fs: FeatherService, private cs:ContactService) { }

  ngOnInit() { }

  save() {
    console.log("email", this.emailFormControl.value);
    console.log("name", this.nameFormControl.value);
    console.log("message", this.messageFormControl.value);

    this.cs.feedbackSave$(this.emailFormControl.value, this.nameFormControl.value, this.messageFormControl.value)
    .subscribe((res) => { console.log(res) });

    // this.fs.service('/api/feedback')
    // .create({ email: this.emailFormControl.value, name: this.nameFormControl.value, message: this.messageFormControl.value , date: new Date() })
    // .then((res) => { console.log(res) }).catch((err) => { console.log(err) });

  }

}
