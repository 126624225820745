import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
// import { SharedModule } from "../shared.module";
// import { EventBusService, Events } from "../../core/services/event-bus.service";
import { SettingService } from "./setting.service";
import * as _ from "lodash";
import { sharedStore,SharedRepository, SharedProps } from "../shared.repository";
import { distinctUntilChanged, map, startWith, tap } from 'rxjs/operators';
import * as JSONCrush from "jsoncrush";
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
// import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout/breakpoints-observer';


import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root' //SharedModule
})
export class UtilService {

  // _colnumber = new BehaviorSubject(1);
  // colnumber$ = this._colnumber.asObservable()
  
  bottomSheetScreens = [
    
    Breakpoints.TabletPortrait,
    Breakpoints.HandsetPortrait
  ];
  
  constructor( private sr:SharedRepository
     , private breakpointObserver:BreakpointObserver,
     private dd: DeviceDetectorService
    ) {
    // eb.set(Events.BibleColumn, Math.floor(window.innerWidth / 50));

    let startCol =  Math.floor(window.innerWidth / 50);
    sr.setColumnsNum(startCol);
    // console.log({startCol})

    fromEvent(window, 'resize').pipe(map(() => {
      // this._colnumber.next(Math.floor(window.innerWidth / 50))
// console.log({wid:window.innerWidth/50})
      let col = Math.floor(window.innerWidth / 50);
      return col;

    }  ), distinctUntilChanged()).subscribe(c=> sr.setColumnsNum(c));



    this.breakpointObserver
    .observe(this.bottomSheetScreens)
    .pipe(
      // tap(st=>console.log({st})),
      map((state) => state.matches )
      // tap((s) => console.log({ breakbot:s }))
    ).subscribe(p=> sr.updatePortaitStatus(p));
    
    this.breakpointObserver
    .observe(Breakpoints.HandsetLandscape)
    .pipe(
      // tap(st=>console.log({st})),
      map((state) => state.matches )
      // tap((s) => console.log({ s }))
    ).subscribe(p=> sr.updateHandsetLandscape(p));

    this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      // tap(st=>console.log({st})),
      map((state) => state.matches )
      // tap((s) => console.log({ hs:s }))
    ).subscribe(p=> sr.updateHandset(p));
    
    this.sr.updateHandset( this.dd.isMobile());
   }

   
  


  // @HostListener('window:resize', ['$event'])
  // onResize() {
  //   // this._colnumber.next(Math.floor(window.innerWidth / 50))
  // }

  



  // reverse one compacted verse key to an array of index objects 
  //for numbers for book, chapter and verse
  reverseKeyString(s: string) {
    let sa= s.split(':')
    let v = sa[2];
    // console.log({ s, v })
    let r;
    if (_.includes(v, ',')) {
      r = v.split(',').map(k => {
        let a = Number(_.trim(k));
        // console.log({a,k})
        return { book: Number(sa[0]), chapter: Number(sa[1]), verse: a }
      });
    } else {

      r = [{ book: Number(sa[0]), chapter: Number(sa[1]), verse: Number(sa[2]) }]
    }
    // console.log({ r })
    return r

  }
  
  //split compacted verse keys to array of individual verse string keys
  splitKeyString(s: string) {
    let vv = s.split(':');
    let v = vv[2]
    // console.log({ s, v })
    let base = `${_.padStart(vv[0],2,'0')}:${_.padStart(vv[1],3,'0')}:`;
    let r;
    if (_.includes(v, ',')) {
      r = v.split(',').map(k => {
        let a = _.trim(k);
        // console.log({a,k})
        return  base + _.padStart(a,2,'0')
      });
    } else {
      return [base + _.padStart(_.trim(v), 2, '0')];
      
    }
    // console.log({ r })
    return r

  }

  // get string key array from compacted string key array
  getAllKeys(sa : string[]){

    let ret = _.flatMap(sa, ks =>  this.splitKeyString(ks)  )
    // console.log({allkeys:ret})
    return ret
  }

  getBookId(key:string){
    // console.log({key: key.substr(0,_.indexOf(key,':'))})
    let ret = Number(key.substr(0,_.indexOf(key,':')) )
    // console.log({ret,key})
    return ret
  }
  uncompress(source:string){
    return  JSONCrush.default.uncrush(source);
 }
 compress(source:string){
   return JSONCrush.default.crush(source);
 }
 // compress json string to url string
 getUrlFromJson(j:any){
  //  console.log({beforecrush:JSON.stringify(j)})
   return encodeURIComponent( this.compress(JSON.stringify(j)))
 }

  // uncompress json string in url
getJsonFromUrl(u:string){
  return JSON.parse(this.uncompress(u))
}

}
