
import { FeatherService } from './feather.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationRequest } from '@feathersjs/authentication/lib';
import { BehaviorSubject } from 'rxjs';
import { faL } from '@fortawesome/free-solid-svg-icons';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isLoggedIn = new BehaviorSubject(false);

  isLoggedIn$ = this.isLoggedIn.asObservable()

  constructor(private feathers: FeatherService, private router: Router) {
    this.logIn().then(() => { this.isLoggedIn.next(true) }).catch(() => { this.isLoggedIn.next(false) });

    this.feathers.fea().on('authenticated', () => {
      this.isLoggedIn.next(true);
    });

    this.feathers.fea().on('logout', () => {
      this.isLoggedIn.next(false);
    });
  }

  public logIn(credentials?: AuthenticationRequest | undefined): Promise<any> {
    return this.feathers.authenticate(credentials).then(() => { this.isLoggedIn.next(true) })
  }

  public logOut() {
    this.isLoggedIn.next(false)

    this.feathers.logout();


    this.router.navigate(['/']);
  }



}
