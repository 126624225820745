import { Injectable } from '@angular/core';
import * as _ from "lodash";
import { HttpClient } from "@angular/common/http";
import { map, mergeMap, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
// import { Versions } from "../models/versions";
import { IVersion } from "../models/iversion";
import { combineLatest, forkJoin, iif, Observable, of } from 'rxjs';
// import { EventBusService, Events } from "../../core/services/event-bus.service";
import { BackendService } from './backend.service';
// import { SharedModule } from "../shared.module";
import { sharedStore, SharedProps, SharedRepository } from "../shared.repository";
import { A, FF_EQUALS } from '@angular/cdk/keycodes';
import { select } from '@ngneat/elf';

import { TranslateService } from '@ngx-translate/core';
import { IVersionSetting } from '../models/iversion-setting';
import { arrayAdd } from '@ngneat/elf/lib/props-array-factory';


// const versions = [
//   "cus,Chinese Union Simplified 简体和合本",
//   "cut,Chinese Union Traditional 繁體和合本",
//   "asv,English American Standard Version",
//   "bbe,English Bible in Basic English",
//   "kjv,English King James Version",
//   "niv,English New International Version",
//   "esv,English Standard Version"
// ];

// const versionInit = versions
//   .map(a => {
//     let b = a.split(',').map(c => c.trim());
//     return ({
//       code: b[0], 
//       name: b[1],
//       main: b[0] == "cus",
//       loaded: ["cus"].includes(b[0]) ? true : false
      
//     }) as unknown
//   }) as IVersionSetting[];


@Injectable({
  providedIn: 'root'
})
export class SettingService {

  // readonly versions = [
  //   "cus, Chinese Union Simplified 简体和合本",
  //   "cut, Chinese Union Traditional 繁體和合本",
  //   "asv, English American Standard Version",
  //   "bbe, English Bible in Basic English",
  //   "kjv, English King James Version",
  //   "niv, English New International Version",
  //   "esv, English Standard Version"
  // ];

  readonly setting ={
    "baseApiUrl": "https://printout.bible",
    "settingApiPath": "/api/setting",
    "historyApiPath": "/api/history",
    "bookmarkApiPath": "/api/bookmark",
    "versionsApiPath":"/api/versions",
    "versionsAdminApiPath":"/api/mongo-versions",
    "bibleApiPath":"/api/bib",
    "starApiPath":"/api/star",
    "starsAdminPath":"/api/mongo-star",
    "imageApiPath":"/api/image",
    "imageAdminApiPath":"/api/mongo-image",
    "categoriesAdminPath":"/api/mongo-categories",
    "categoriesPath":"/api/categories",
    "imageUploadPath":"/api/upload",
    "cacheResetPath":"/api/cache-reset",
    "logApiPath":"/api/log",
    "usersApiPath":"/api/users",
    "feedbackApiPath":"/api/feedback",
    "imageBaseUrl":"https://printout.bible/background"
  
  };
  
  versionSetting$ = sharedStore.pipe(select(st => st.versionSetting))

  constructor(private http: HttpClient,
    // private eb: EventBusService,
    private bs: BackendService,
    private sr: SharedRepository,
    private translate: TranslateService) {

  }


  versionsInit$ = this.sr.versions$.pipe(
    map(a => _.chain(a).filter(v=>!v.hide)
    .map(vr=>({
      code:vr.code, 
      name:vr.name, 
      main:vr.code == "esv",
      loaded:["esv"].includes(vr.code) ? true : false}))
    .value() as IVersionSetting[])
  )

  currentLanguage$ = this.sr.appLanguage$

  useLanguage(lang:string){

    this.translate.use(lang);
    console.log({use:lang})
    this.sr.updateAppLanguage(lang)

    this.sr.settingUrl$.pipe(take(1),withLatestFrom(this.sr.versionSetting$, this.sr.appbackground$))
    .subscribe(([u,version,b]) => {
      let r = _.map(version, f => _.pickBy(f, (v, k) => k != "file"))
      // console.log({settingu:u, updated:r})
      this.bs.put(u, { user: "temp", content: { version: r,appbackground:b,applanguage:lang, dictioary: 'not set' } })

    })

  }

  setDefaultLang(lang:string){
    this.translate.setDefaultLang(lang)
    this.sr.updateAppLanguage(lang)
  }


  appbackground$ = this.sr.appbackground$

  updateAppbackground(b:string){

    let r = document.documentElement;
    let sty = getComputedStyle(r)
    r.style.setProperty('--backGroundImg', `url('${b}')`)
    
    this.sr.updateAppbackground(b);

    this.sr.settingUrl$.pipe(take(1),withLatestFrom(this.sr.versionSetting$, this.sr.appLanguage$))
    .subscribe(([u,version,lang]) => {
      let r = _.map(version, f => _.pickBy(f, (v, k) => k != "file"))
      // console.log({settingu:u, updated:r})
      this.bs.put(u, { user: "temp", content: { version: r,appbackground:b,applanguage:lang, dictioary: 'not set' } })
    })
   }
   updateAppbackgroundCustomized(b:string){

    let r = document.documentElement;
    let sty = getComputedStyle(r)
    r.style.setProperty('--backGroundImg', `url('${b}')`)
    
    this.sr.updateAppbackground(b);
  }



  setAppBackgroundImage(file:File){
        // var selectedFile = event.target.files[0];
        var reader = new FileReader();
  
        reader.onload = function(event) {
          // this.updateAppbackgroundCustomized("")    
          let src = event.target?.result;
          console.log({src})
    
          let r = document.documentElement;
          let sty = getComputedStyle(r)
          r.style.setProperty('--backGroundImg', `url('${src}')`)
    
        };
      
        reader.readAsDataURL(file);
  }

  initAppbackground(b:string){

    let r = document.documentElement;
    let sty = getComputedStyle(r)
    r.style.setProperty('--backGroundImg', `url('${b}')`)

    this.sr.updateAppbackground(b);
    
  }


  // initBookNames(){
  //  this.bs.get<any>("assets/bibTitle.json")
  //   .pipe(map(a => a.books as IBook[]),tap( b=> sharedStore.update(st=> ({...st, bookNames:b  }))  )).subscribe();
  // }

  // initVersions(){

  //   this.sr.versionSetting$.pipe(
  //     mergeMap( v => iif(()=>v.length>0, of(v), this.initVersions$()))
  //   )
  //   .subscribe(a=>console.log({a}))
    
  // }

  initVersions(){

    return this.sr.versionsUrl$.
    pipe(
      tap(a=>console.log({a})),
      take(1),
      mergeMap(u=> this.bs.get1(u) ),
      tap((v ) => this.sr.sharedstore.update(st=>({...st, versions:(v as IVersion[])}))))
      .subscribe(ver=> console.log({ver}));
      
  }


  initImagesLoad(){
    this.sr.imageUrl$.
    pipe(
      tap(a=>console.log({a})),
      take(1),
      mergeMap(u=> this.bs.get1(u) ),
      tap((v:any ) => this.sr.sharedstore.update(st=>({...st, images:v}))))
      .subscribe(ver=> console.log({ver}));
  }
  // loadFile(code: string) {
  //   return this.bs.get(`assets/${code}.json`)
  // }


  getAppBackgroundImages$() {
    console.log({image:'called'})
    return combineLatest([this.sr.appBackgroundImages$, this.sr.imageBaseUrl$]) 
    .pipe(
      tap(ii=>console.log({ii})),
      map(([i,p]) => {
      // var f= i.filter(b => category == null || b.category.toLowerCase() == category.toLowerCase())
      return i.map(i=> ({...i, 
        listItem:{src:i.name=='999'?'': i.name.length <5 ? `${p}/${i.name}.jpg`
        :`${p}/${i.name}`,caption:i.name,code:i.name }}))
    
    }
      
      ),
      tap(iii=>console.log({iii}))
      )
  }
 
  initUrlandVersionSetting() {
    of(this.setting)
      .pipe(tap(s => {
        console.log({s})
        sharedStore.update(st =>
        ({
          ...st,
          ApiUrl:
          {
            base: (s as any).baseApiUrl,
            setting: s.settingApiPath,
            history: s.historyApiPath,
            bookmark: s.bookmarkApiPath,
            versions:s.versionsApiPath,
            versionsAdmin:s.versionsAdminApiPath,
            bible:s.bibleApiPath,
            star:s.starApiPath,
            image:s.imageApiPath,
            imageAdmin:s.imageAdminApiPath,
            imageUpload:s.imageUploadPath,
            categories:s.categoriesPath,
            categoriesAdmin:s.categoriesAdminPath,
            starsAdmin:s.starsAdminPath,
            cacheReset:s.cacheResetPath,
            imageBaseUrl:s.imageBaseUrl,
            
            logApiPath:s.logApiPath,
            usersApiPath:s.usersApiPath,
            feedbackApiPath:s.feedbackApiPath,
          }
          ,
        }))
      }),
        mergeMap(
          b => this.bs.get1<any>(b.baseApiUrl + b.settingApiPath).pipe(
            mergeMap(
              ([aa]) => {
                // console.log({ aa });
                //init vback ground
                let b = aa?.content?.appbackground as string;
                if(b){
                  this.initAppbackground(b);
                }
                //init language
                let l = aa?.content?.applanguage as string;
                if(l){
                  this.setDefaultLang(l);
                }

                let v = (aa?.content?.version as IVersionSetting[]);
                // console.log({ v });
                if (false && v && v.length > 0) {
                  // let vv = v.map(b => _.assignIn(b, { file: this.loadFile((b as any).code) }))
                  // this.settings.next(vv); //this will udpate local db
                  return of(v);
                } else {
                  // let vvv = this.settingsInit.map(c => ({ code: c.code, name: c.name, main: c.main, loaded: c.loaded }));
                  // this.settings.next(this.settingsInit);
                  // return versionInit as IVersionSetting[];
                  this.initVersions(); ////?????????????????????

                  return this.versionsInit$;
                }
              }
            )
            // ,
            // tap(aaa => console.log({ aaa }))

          )

        ),

        // map(a => a),
        // tap(v => { sharedStore.update(st => ({ ...st, versionSetting: v, })) }),

        // mergeMap(g => {
        //   let loaded = g.filter(e => e.loaded)
        //   let files = loaded.map(f => this.loadFile(f.code))
        //   return forkJoin([...files])
        //     .pipe(map(i => {
        //       let l = _.zipWith(loaded, i, (l, j) => ({ ...l, file: j }) as IVersion)
        //       return g.map(j => _.some(l, k => k.code == j.code) ? l.find(m => m.code == j.code) : j) as IVersion[]
        //     }

        //     ))

        // }),
tap(v=>console.log({v})),
        tap(v => { if(sharedStore.getValue().versionSetting == null) sharedStore.update(st => ({ ...st, versionSetting: v })) }),
        // tap(aaafff => console.log({ aaafff }))

      ).subscribe()


  }


  updateVersion(ver: IVersionSetting[]) {

    // let version = _.values(_.assign({}, ver)) as IVersionSetting[];

    // let oldVersion = sharedStore.getValue().versionSetting;
    // // console.log({version,oldVersion})
    // let updated = _.zipWith(version, oldVersion,(n,o)=> _.assign(n, {file:o.file}) ) as IVersion[];

    // save to DB 
    this.sr.settingUrl$.pipe(take(1),withLatestFrom(this.sr.appLanguage$, this.sr.appbackground$))
    .subscribe(([u,l,b]) => {
      // let r = _.map(version, f => _.pickBy(f, (v, k) => k != "file"))
      // console.log({settingu:u, updated:r})
      this.bs.put(u, { user: "temp", content: { version: ver,appbackground:b,applanguage:l, dictioary: 'not set' } })

    })

    //save to store
    sharedStore.update(st => ({ ...st, versionSetting: Array.from(ver) }))

console.log({ver})
  }




}
