import { Injectable } from '@angular/core';
import { combineLatest, forkJoin, map, mergeMap } from 'rxjs';
import { BackendService } from 'src/app/shared/services/backend.service';
import { SharedRepository } from 'src/app/shared/shared.repository';

import * as _ from "lodash";

@Injectable({
  providedIn: 'root'
})
export class ChapterService {

  constructor(private bs: BackendService, private shr: SharedRepository) { }

  verses$ = (book, chapter) => {
    // console.log({book,chapter})
    return combineLatest([this.shr.versionSetting$, this.shr.bibleUrl$])
      .pipe(mergeMap(([vs, url]) => {
        // console.log({vs,url})
        let versions = vs.filter(v => v.loaded).map(v1 => v1.code).join('|')
        return this.bs.get(url, {book:book,chapter:chapter,version:versions} )//`${url}?book=${book}&chapter=${chapter}&version=${versions}`)
        
          .pipe(map(b => b['data'] as any[]),
            map(b1 => {
              let mainversion = vs.find(v => v.main)?.code

              let verses = b1.map(b2 => b2.data.map((b3: any) => ({ ...b3, version: b2.version, main: b2.version == mainversion })
              
              ))

              return _.chain(verses).flatten().orderBy(['verse','main'],['asc','desc']).groupBy(v=>v.verse).toPairs().value().map(v=>({id:v[0],verses:v[1]}))
            })
          )
      })
      )
  }


  chapterTitle$ = (book, chapter) => {
    return combineLatest([this.shr.versions$, this.shr.main$]).pipe(map(([v, m]) => {
      return { book: v.find(ver => ver.code == m.code)?.content?.find(b => b.id == Number(book))?.name, chapter: chapter }
    }))
  }

}
