import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs';
import { BackendService } from 'src/app/shared/services/backend.service';
import { SharedRepository } from 'src/app/shared/shared.repository';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private bs: BackendService, private shr: SharedRepository) { }

  users$ = this.bs.get$(this.shr.userApiPath$);
  
  // this.shr.userApiPath$.pipe(
  //   switchMap((u: string) => this.bs.get(u))
  // );
}
