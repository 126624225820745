import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { BoardComponent } from './admin/components/board/board.component';
import { BibleToolbarComponent } from './bible/components/bible-toolbar/bible-toolbar.component';
import { BookComponent } from './bible/components/book/book.component';
import { BooksComponent } from './bible/components/books/books.component';
import { ChapterComponent } from './bible/components/chapter/chapter.component';
import { HistoryComponent } from './bible/components/history/history.component';
import { SearchToolbarComponent } from './bible/components/search-toolbar/search-toolbar.component';
import { SearchComponent } from './bible/components/search/search.component';
import { StarComponent } from './bible/components/star/star.component';
import { HelpComponent } from './core/components/help/help.component';
import { WelcomeComponent } from "./core/components/welcome/welcome.component";
import { PrintToolbarComponent } from './print/components/print-toolbar/print-toolbar.component';
import { PrintComponent } from './print/components/print/print.component';
import { LoginComponent } from './shared/components/login/login.component';
import { RegisterComponent } from './shared/components/register/register.component';
import { ResetPasswordRequestComponent } from './shared/components/reset-password-request/reset-password-request.component';
import { ResetPasswordComponent } from './shared/components/reset-password/reset-password.component';

import { SettingComponent } from "./shared/components/setting/setting.component";
import { SignupVerificationComponent } from './shared/components/signup-verification/signup-verification.component';
import { AuthGuard } from './shared/guards/auth.guard';


const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'books',
    pathMatch: 'full'
  },
  {path:'welcome',component:WelcomeComponent},
  {path:'settings',component:SettingComponent},
  {path:'books',component:BooksComponent},
  { path: 'book/:book', component: BookComponent },
  {path:'chapter/:book/:chapter', component:ChapterComponent },
  { path: 'chapter/:book/:chapter', component: BibleToolbarComponent, outlet:"toolbarLeft" },
  { path: 'chapter/:book/:chapter/:verse', component: ChapterComponent },
  { path: 'chapter/:book/:chapter/:verse', component: BibleToolbarComponent, outlet:"toolbarLeft" },

  {path:'search',component:SearchComponent},
  {path:'search',component:SearchToolbarComponent,outlet:'toolbarLeft'},
  {path:'star',component:StarComponent},
  { path: 'star', component: BibleToolbarComponent, outlet:"toolbarLeft" },
  
  { path: 'history', component: HistoryComponent },
  { path: 'history', component: BibleToolbarComponent, outlet:"toolbarLeft" },
  { path: 'print', component: PrintComponent },
  { path: 'print', component: PrintToolbarComponent, outlet:"toolbarRight" },
  {path:'admin',component:BoardComponent, canActivate:[AuthGuard]},
  {path:'register',component:RegisterComponent ,canActivate:[AuthGuard]},
  {path:'signup-verification',component:SignupVerificationComponent},
  
  {path:'reset-password',component:ResetPasswordComponent},
  {path:'reset-password-request',component:ResetPasswordRequestComponent},
  {path:'login',component:LoginComponent},
  {path:'help',component:HelpComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
