import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, mergeMap, ReplaySubject, Subject } from 'rxjs';
import { BackendService } from 'src/app/shared/services/backend.service';
import { SharedRepository } from 'src/app/shared/shared.repository';

import * as _ from "lodash";

@Injectable({
  providedIn: 'root'
})
export class SearchService {
 
  searchKey = new ReplaySubject(1);
  searchKey$ = this.searchKey.asObservable()
  searchType = new BehaviorSubject<string>('and')
  searchType$ = this.searchType.asObservable()

  searchOrdered = new BehaviorSubject<boolean>(false);
  searchOrdered$ = this.searchOrdered.asObservable();

  searchMain = new BehaviorSubject<boolean>(true);
  searchMain$ = this.searchMain.asObservable();


  constructor(private bs: BackendService, private shr: SharedRepository) { }

  setSearchKey(k:string[]){
    
    this.searchKey.next(k.join('|'))

  }

  
  search$(params:any= null){
  
    return combineLatest([this.shr.main$,this.shr.bibleUrl$,this.shr.versions$,
       this.searchKey$,this.searchType$,this.searchOrdered$,this.searchMain$,this.shr.loaded$])
    .pipe(mergeMap(([m,bu,v,k,t,o,sm,l])=> {
      console.log({k,t,o,sm,l})
      let ver = sm? m.code:l.map(ver=>ver.code).join('|')
      return this.bs.get(bu, {version:ver,key:k,type:t,ordered:o} )
      .pipe(map(b => b['data'] as any[]),
      map(b1 => {
        let mainversion = m.code

        let verses = b1.map(b2 => b2.data.map((b3: any) => 
        {
          // console.log({b3,v})
          return { ...b3, 
            bookName: v.find(ver=>ver.code==b2.version).content.find(bo=>bo.id ==b3.book).name,
            version: b2.version, main: b2.version == mainversion }

        }
        
        ))

        return _.chain(verses)
        .flatten()
        .orderBy(['verse','main'],['asc','desc'])
        .groupBy(v=>`${v.bookName} ${String(v.chapter).padStart(3,'0')}:${String(v.verse).padStart(2,'0')}`)
        .toPairs().map(v=>({id:v[0].split(' ').map(str=> _.trimStart(str,'0')).join(' '),verses:v[1]}))
        .sortBy(vv=>vv.verses[0].key).value()
      })
    )
     }))


  }

  
}
