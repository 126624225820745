import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linebreak'
})
export class LinebreakPipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    if (value) {
      return value.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>');
    }
    return value;
  }

}
