import { Injectable } from '@angular/core';
import { combineLatest, map, mergeMap, take, tap } from 'rxjs';
import { BackendService } from 'src/app/shared/services/backend.service';
import { SharedRepository } from 'src/app/shared/shared.repository';
import * as _ from "lodash";
import { FeatherService } from 'src/app/shared/services/feather.service';

@Injectable({
  providedIn: 'root'
})
export class StarsService {

  constructor(private bs: BackendService, private shr: SharedRepository, private fs: FeatherService) { }

  stars$ = combineLatest([this.shr.versionSetting$, this.shr.versions$, this.shr.starUrl$])
    .pipe(mergeMap(([vs, v, bu]) => {
      return this.bs.get(bu, { version: vs.find(v => v.main)?.code })
        .pipe(map((r: any) => {
          return _.map(r, t => {
            return {
              ...t,
              bookName: _.find(v, vn => vn.code == vs?.find(vsi => vsi?.main)?.code)
                .content.find(b => b.id == t.verses[0].book).shortName
            }
          })
        }
        ))

    }))

  updateStar(star: any) {

    this.shr.starsAdminPath$.pipe(
      take(1),
      mergeMap(u => this.fs.update(u, star._id, star))
    ).subscribe(a => console.log({ a }))

    //   this.shr.starsAdminUrl$.pipe(
    //     tap(uu=>console.log({uu})),
    //     map(u=> this.bs.put(u+"/"+star._id,star)),
    //     // tap(uuu=>console.log({uuu})),
    //     ).subscribe()

  }
}
