import { Component } from '@angular/core';
import { FeatherService } from './shared/services/feather.service';

import { SettingService } from "./shared/services/setting.service";
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private ss: SettingService, private auth: FeatherService) {
    // auth.authenticate({strategy:'anonymous'})
    this.initialize(ss)
  }

  // create method initialize method
   initialize(ss:SettingService) {
    // ss.initVersions();
    
    //versionsetting depends on the versions //// this is wrong....!!!!
    ss.initUrlandVersionSetting();
    ss.setDefaultLang('en-CA');
    
    ss.initImagesLoad();
   }

   test() {
    console.log('test');
    // call settingservice to get the setting
    
  
  }
   
}
