import { Injectable } from '@angular/core';
import { combineLatest, from, map, mergeMap } from 'rxjs';
import { BackendService } from 'src/app/shared/services/backend.service';
import { SharedRepository } from 'src/app/shared/shared.repository';
import { BookService } from './book.service';
import * as _ from "lodash";
import { FeatherService } from 'src/app/shared/services/feather.service';
@Injectable({
  providedIn: 'root'
})
export class StarService {

  constructor(private bs: BackendService, private shr: SharedRepository
    , private fs: FeatherService) { }

  star$ = combineLatest([this.shr.versionSetting$, this.shr.versions$, this.shr.starUrl$])
    .pipe(mergeMap(([vs, v, bu]) => {
      // console.log("star$", vs, v, bu);
      return from(this.fs.service('api/star').find({query:{ version: vs.find(v => v.main)?.code }}))
      // return this.bs.get(bu, { version: vs.find(v => v.main)?.code })
        .pipe(map((r: any) => {
          console.log({r})
          return _.map(r, t => {
            return {
              ...t,
              bookName:_.find(v, vn => vn.code == vs?.find(vsi => vsi?.main)?.code)
              .content.find(b => b.id == t.verses[0].book).shortName
            }
          })
        }
        ))

    }))

}
