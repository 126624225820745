import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, map, Subject, takeUntil, tap, withLatestFrom } from 'rxjs';
import { SharedRepository } from 'src/app/shared/shared.repository';
import { BibleToolbarService } from '../../services/bible-toolbar.service';

import { StarService } from '../../services/star.service';
import * as _ from "lodash";
import { NestedTreeControl } from '@angular/cdk/tree';

interface IStarNode {
  name: string;
  children?: IStarNode[];
}


@Component({
  selector: 'app-star',
  templateUrl: './star.component.html',
  styleUrls: ['./star.component.scss'],
})
export class StarComponent implements OnInit ,OnDestroy {
  end = new Subject<void>()
  end$ = this.end.asObservable();
  selection = new SelectionModel<any>(true, []);

  checklistSelection = new SelectionModel<IStarNode>(true); //multiple


  isEsvMain$ = this.shr.isEsvMain$
  
  constructor(private router: Router, private sts: StarService,
    private bts: BibleToolbarService,
    private shr: SharedRepository) {

    this.bts.printAction$
      .pipe(
        withLatestFrom(shr.main$,shr.versions$),
        tap(([a,m,v]) => {
          console.log('star print trigggered...')
          let zz = this.selection.selected.length>0 ?  
            _.chain(this.selection.selected).map( a =>
            {
              let bn = v.find(ver=>ver.code==m.code)?.content?.find(b=>b.id==a.verses[0]?.book)?.name
            return   _.map(a.verses,
              v=> ({
                bookId: v.book, bookName: bn, verseId: v.verse,
                chapterId: v.chapter, verseT: v.content, 
                date: new Date(), version: m.code
              }))
            }).flatten().value()
            :
            _.chain(this.checklistSelection.selected)
            .map((a:any)=>{
              return _.map(a.verses, v=> ({
                bookId: v.book, bookName: a.bookName, verseId: v.verse,
                chapterId: v.chapter, verseT: v.content, 
                date: new Date(), version: m.code
              })  )
            } ).flatten().value()
          // console.log({ zz })
          shr.printContent(zz)
          this.router.navigate([{ outlets: { primary: ['print'], toolbarLeft: null, toolbarRight: ['print'] } }]);
        }
        ),
        takeUntil(this.end$)
      ).subscribe()
  }
  ngOnDestroy(): void {
    this.end.next();
    this.bts.hidePrintButton();
  }



checkChanged(item: any) {
  this.selection.toggle(item);
  console.log('checkchanged..')
  if (this.selection.selected.length > 0) {
    // this.eb.set(Events.ShowPrint, true)
    this.bts.showPrintButton()
  }
  else {
    // this.eb.set(Events.ShowPrint, false)
    this.bts.hidePrintButton()
  }
  console.log(this.selection.selected.length)
}

ngOnInit() { }

stars$ = this.sts.star$.pipe(tap(console.log))
starTopics$ = this.sts.star$.pipe( /// duplicate calls to be removed
  map(sa=>{
    return _.chain(sa)
    .map(i=> i.topics.map(t=>({...i,topic:t})))
    .flatten()
    .groupBy(g=>g.topic)
    .map((v,k)=> {
      let children = v.map(i=> ({...i,name:i.bookName+':' + i.id.slice(3)+ ' '+ i.verses.map(ver=>ver.content).join(' ') as string }) )
      return {name:k.toLowerCase(),children:children} as IStarNode
    } )
    .value()

  }    
  )
   ,
   tap((st:any)=>console.log({s:_.map(st,(s,i)=>({id:i,name:s.name})) }))
  

)

linkClick(e: any) {
  console.log({ e })
  let row = e.verses[0];
  this.router.navigate([{ outlets: { primary: ['chapter', row.book, row.chapter, row.verse], toolbarLeft: ['chapter', row.book, row.chapter, row.verse], toolbarRight: null } }]);
}

treeControl = new NestedTreeControl<IStarNode>(node => node.children);
// dataSource = new MatTreeNestedDataSource<starNode>();
hasChild = (_: number, node: IStarNode) => !!node.children && node.children.length > 0;

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  itemSelectionToggle(node: IStarNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.forEach(child => this.checklistSelection.isSelected(child));

    //this.checkAllParentsSelection(node);
  }
  
  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  leafItemSelectionToggle(node: IStarNode): void {
    this.checklistSelection.toggle(node);
    console.log({catChecked: this.checklistSelection})
  //  this.checkAllParentsSelection(node);
  if (this.checklistSelection.selected.length > 0) {
    // this.eb.set(Events.ShowPrint, true)
    this.bts.showPrintButton()
  }
  else {
    // this.eb.set(Events.ShowPrint, false)
    this.bts.hidePrintButton()
  }
  }

}
