import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, mergeMap } from 'rxjs';
import { FeatherService } from '../../services/feather.service';

@Component({
  selector: 'app-signup-verification',
  templateUrl: './signup-verification.component.html',
  styleUrls: ['./signup-verification.component.scss'],
})
export class SignupVerificationComponent implements OnInit {
  verificationSuccessTitle = "Email verified successully";
  verificationSuccessMessage = "Your email has been verified successfully. You can now login to your account.";
  verificationFailTitle = "Email verification failed";
  verificationFailMessage = "Your email verification failed. Please try again later.";

  sucess = new BehaviorSubject(false);
sucess$ = this.sucess.asObservable();

  constructor(private fs:FeatherService,private route: ActivatedRoute) {

    route.queryParams
      // .pipe(mergeMap(p => this.fs.verifySignup$(p.token)))
      // .subscribe(
      //   () => this.sucess.next(true),
      //   () => this.sucess.next(false)
      // );
    .pipe( mergeMap( p => {
      if (  p.token != null ) {
        console.log("token",p.token);
     
        return fs.create('api/auth-management',{ action:'verifySignupLong', value: p.token ,notifierOptions:{}})
        }
        else {
          return null;
        }
    
    })).subscribe(
      {
       next:(res:any)=>{
         this.sucess.next(true);
         console.log({res  })
       },
       error:(err:any)=>{
         this.sucess.next(false);
         console.log({err})
       }


      }
     ) ;
    
    // const { VerifySignupShortService } = require('feathers-authentication-management');
// app.use("auth-management/verify-signup-short", new VerifySignupShortService(app, options));



   }

  ngOnInit() {}

}
