import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BibleToolbarService {

  showPrint = new BehaviorSubject(false);
  showPrint$ = this.showPrint.asObservable()

  showBookmarkbutton = new BehaviorSubject(false)
  showBookmarkbutton$ = this.showBookmarkbutton.asObservable()

  showChapterNavBtn = new BehaviorSubject(false)
  showChapterNav$ = this.showChapterNavBtn.asObservable().pipe(tap((b) => console.log({ showchapnav: b })))

  showDeletehistory = new BehaviorSubject(false)
  showDeletehistory$ = this.showDeletehistory.asObservable();

  goPrevChap = new Subject<void>()
  goPrevChap$ = this.goPrevChap.asObservable()

  goNextChap = new Subject<void>()
  goNextChap$ = this.goNextChap.asObservable();


  printAction = new Subject<void>()
  printAction$ = this.printAction.asObservable()

  saveBookmarkAction = new Subject<void>()

  saveBookmarkAction$ = this.saveBookmarkAction.asObservable()


  constructor(private router: Router) {


  }

  showPrintButton() {
    this.showPrint.next(true);
  }

  hidePrintButton() {
    this.showPrint.next(false);
  }

  showBookmarkButton() {
    this.showBookmarkbutton.next(true)
  }
  hideBookmarkButton() {
    this.showBookmarkbutton.next(false)
  }

  showChapterNav() {
    this.showChapterNavBtn.next(true)

  }
  hideChapterNav() {
    this.showChapterNavBtn.next(false)
  }


  hideDeleteButton() {
    this.showDeletehistory.next(false);
  }

  showDeleteButton() {
    this.showDeletehistory.next(true)
  }

  goNext() {
    this.goNextChap.next()
  }
  goPrev() {
    this.goPrevChap.next()
  }

  printBible() {
    this.printAction.next();
  }

  saveBookmark() {
    this.saveBookmarkAction.next()
  }
}
