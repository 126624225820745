import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs';
import { BackendService } from 'src/app/shared/services/backend.service';
import { SharedRepository } from 'src/app/shared/shared.repository';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private bs: BackendService, private shr: SharedRepository) { }

  log$ = this.bs.get$(this.shr.logApiPath$);
  
  // this.shr.logApiPath$.pipe(
  //   switchMap((u: string) => this.bs.get(u))
  // )
}
