import { Injectable } from '@angular/core';
import { Router, NavigationEnd, RouterEvent, NavigationStart } from '@angular/router'
// import { CoreModule } from "../core.module";
import { Location } from '@angular/common'
// import { EventBusService, Events } from "./event-bus.service";
import { takeUntil, tap } from 'rxjs/operators';
import { SharedRepository, sharedStore } from 'src/app/shared/shared.repository';
// import { Shared1Repository } from 'src/app/shared/shared1.repository';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavService {

  private historyCounter: number = 0;
  private hist = "historyCounter";
  showHistoryBackbutton = new Subject();
  showHistoryBackbutton$ = this.showHistoryBackbutton.asObservable()

  end = new Subject<void>()
  end$ = this.end.asObservable();
  

  ngOnDestroy(): void {
    this.end.next();
    this.end.complete();
  }

  constructor(private router: Router, private location: Location
    ,private shr:SharedRepository
    ) {

    this.historyCounter = Number(sessionStorage.getItem(this.hist) ?? 0);
    if (this.historyCounter > 0) this.historyCounter--;

    this.router.events.pipe(
      tap((event) => {
        //gocack history action
        // console.log({event:event, sharedstore: sharedStore.getValue() })

        if (event instanceof NavigationEnd) {
          this.historyCounter += 1;
          sessionStorage.setItem(this.hist, String(this.historyCounter))
          if (this.historyCounter > 1) {
            this.showHistoryBackbutton.next(true)
            // this.eb.set(Events.NavGoBackHisotryButtonShowEntity, true);
          } else {
            this.showHistoryBackbutton.next(false);
            // this.eb.set(Events.NavGoBackHisotryButtonShowEntity, false);
          }

          // console.log({ nav: this.historyCounter });
        }
      }),
      tap(e => {
        if (e instanceof NavigationStart) {
          let c = e as RouterEvent;
          // if (c.url != "/history")
          //   eb.set(Events.ShowDelete, false);
          // if (c.url.startsWith("/chapter")) {
            
          //   eb.set(Events.ShowChapterNav, true)
          // } else {
          //   eb.set(Events.ShowChapterNav, false)
            

          // }
          // eb.set(Events.ShowBookMark, false)
          // eb.set(Events.ShowPrint, false)
        }
        // console.log({routeevent: e})
      }),
      takeUntil(this.end$)
    ).subscribe();

    // this.eb.get(Events.NavGoBackHisotryAction)
    //   .pipe(tap(() => this.back()))
    //   .subscribe(() => console.log('NavGoBackHisotryAction, raised'));


  }

  back(): void {


    if (this.historyCounter > -1)
      sessionStorage.setItem(this.hist, String(this.historyCounter));
    // sessionStorage.setItem(this.hist, String(this.historyCounter))
    // console.log({ navback: (this.router.getCurrentNavigation()) });
    // console.log({ navback: this.historyCounter });
    console.log({ navback: this.historyCounter });
    if (this.historyCounter > 1) {

      this.historyCounter -= 2;
      this.location.back()

    } else {

      this.router.navigateByUrl('/');
      // this._me.cast(LocalEvent.GoBackHisotryButtonShowEntity, false);
    }
  }
}
