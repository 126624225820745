import { Injectable } from '@angular/core';
import { BackendService } from 'src/app/shared/services/backend.service';
import { SharedRepository } from 'src/app/shared/shared.repository';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private bs: BackendService, private shr: SharedRepository) { }

  feedbackSave$(email: string, name: string, message: string) {
    return this.bs.post$(this.shr.feedbackApiPath$, { email: email, name: name, message: message, date: new Date() });
  }  

}
