import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeatherService } from '../../services/feather.service';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  form: any = {
    username: null,
    email: null,
    password: null,
    confirmPassword: null
  };
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';
  message = '';

  data: {} = {};

  messages: string[] = [];

  constructor(private feathers: FeatherService, private router: Router) {

  }

  onSubmit(): void {
    this.message = '';
    this.errorMessage = '';

    const { username, email, password, confirmPassword } = this.form;
    if (!email || !password || !confirmPassword || !username) {
      this.errorMessage =='Incomplete credentials!';
      return;
    }
    if (password !== confirmPassword) {
      this.errorMessage ='Passwords do not match!';
      return;
    }

    this.feathers.service('api/users')
      .create({ email, password, name: username, permissions: ["admin:*"] })
      .then(() => {
        console.log('User created.');
        this.isSuccessful = true;
        this.isSignUpFailed = false;
        this.message='User created. Please go to yur email and confirm your account.'
        //this.router.navigate(['/welcome']);
        
      }
      )
      .catch((err: any) => {
        console.log('User failed to create.');
        this.errorMessage = err.message;
        this.isSignUpFailed = true;
      })
      ;

  }


  login(email: string, password: string) {
    if (!email || !password) {
      this.messages.push('Incomplete credentials!');
      return;
    }

    // try to authenticate with feathers
    this.feathers.authenticate({
      strategy: 'local',
      email,
      password
    })
      // navigate to base URL on success
      .then(() => {
        this.router.navigate(['/welcome']);
      })
      .catch(err => {
        this.messages.unshift('Wrong credentials!');
      });
  }

  signup(email: string, password: string) {
    this.feathers.service('api/users')
      .create({ email, password, name: password,permissions:["admin:*"] })
      .then(() => this.messages.push('User created.'))
      .catch((_: any) => this.messages.push('Could not create user!'))
      ;
  }


  logout() {
    this.feathers.logout();
  }
  getHist() {
    this.feathers.service("api/history").find({}).then((data: any) => this.data = data);
  }
  ngOnInit(): void {
  }

}
