import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { from, fromEvent, Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from "@angular/router";
import { debounceTime, distinctUntilChanged, map, shareReplay, tap } from 'rxjs/operators';
// import { EventBusService,Events } from '../../services/event-bus.service';
import { NavService } from '../../services/nav.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { App } from '@capacitor/app'
import { IonBackButton } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { SharedRepository } from 'src/app/shared/shared.repository';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {

  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  // showBookMark$ = this.eb.get(Events.ShowBookMark).pipe( tap(b => console.log({ b })));
  // showChapterNav$ = this.eb.get(Events.ShowChapterNav);

  // showPrint$ = this.eb.get(Events.ShowPrint).pipe(tap(b => console.log({ b })));

  showHistoryBackButton$ = this.ns.showHistoryBackbutton$
    // this.eb.get(Events.NavGoBackHisotryButtonShowEntity)
    .pipe(tap(b => console.log({ navi: b })));

  // ShowDeleteHistoryAction$ = this.eb.get(Events.ShowDelete)
  //   .pipe(distinctUntilChanged(), debounceTime(300));

  showExitButton$ = of(!(Capacitor.getPlatform() === 'web'))

  constructor(private router: Router,
    private breakpointObserver: BreakpointObserver,
    private auth: AuthService,
    private sr: SharedRepository,

    private ns: NavService) {
    fromEvent(document, 'ionBackButton').subscribe( _ => App.exitApp() )
  }

  ngOnInit(): void {
  }

  goBackHistory() {
    this.ns.back()
    // this.eb.set(Events.NavGoBackHisotryAction);
    console.log('gobackhistoryaction')
  }


  saveBookMark() {
    // this._file.saveBookMarkAction();
    // this.eb.set(Events.SaveBookMarkAction);
  }
  printOut() {
    // this.eb.set(Events.printBibleAction);
    //this.goPrint();
  }

  deleteHistory() {
    // this.eb.set(Events.HistoryDelete);

  }


  goBack() {
    // this.router.navigate(['/books']);
    this.router.navigate([{ outlets: { primary: ['books'], toolbarLeft: null, toolbarRight: null } }]);
  }

  goHome() {
    // this.router.navigate(['/books']);
    this.router.navigate([{ outlets: { primary: ['welcome'], toolbarLeft: null, toolbarRight: null } }]);
  }


  goBooks() {
    // this.router.navigate(['/books']);
    this.router.navigate([{ outlets: { primary: ['books'], toolbarLeft: null, toolbarRight: null } }]);
  }
  goSearch() {
    // this.router.navigate(['/search']);
    this.router.navigate([{ outlets: { primary: ['search'], toolbarLeft: ['search'], toolbarRight: null } }]);


  }

  goPrint() {
    this.router.navigate([{ outlets: { primary: ['print'], toolbarLeft: null, toolbarRight: ['print'] } }]);
  }

  goSettings() {
    this.router.navigate([{ outlets: { primary: ['settings'], toolbarLeft: null, toolbarRight: null } }]);
  }
  goHistory() {
    // this.router.navigate(['/history']);
    this.router.navigate([{ outlets: { primary: ['history'], toolbarLeft: ['history'], toolbarRight: null } }]);
  }
  goStar() {
    this.router.navigate([{ outlets: { primary: ['star'], toolbarLeft: ['star'], toolbarRight: null } }]);
  }

  goPrevious() {
    // this.eb.set(Events.NavGoPreviousAction)
    // this.router.navigate(['/chapter/1/10']);
  }
  goNext() {
    // this.eb.set(Events.NavGoNextAction)
  }
  goBoard() {
    this.router.navigate([{ outlets: { primary: ['admin'], toolbarLeft: null, toolbarRight: null } }]);
  }
  goHelp() {
    this.router.navigate([{ outlets: { primary: ['help'], toolbarLeft: null, toolbarRight: null } }]);
  }

  logout() {

    this.auth.logOut();
  }
  exitApp() {
    App.exitApp()
  }

  isLoggedIn$ = this.auth.isLoggedIn$;
  // isEsvMain$ = this.sr.versionSetting$.pipe(
  //   tap(b => console.log({ b })),
  //   map(m => m.find(v=>v.main).code === 'esv'),tap(b => console.log({ b })));

    // isEsvMain$ = this.sr.main$.pipe(map(m => m.code === 'esv'));
}
