import { Injectable } from '@angular/core';
import { Store, createState, createStore } from '@ngneat/elf';
import { withEntities, selectEntities, addEntities, deleteEntities } from '@ngneat/elf-entities';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import { tap } from 'rxjs';

export interface Bookmark {
  _id: number;
  book:number,
  chapter:number,
  verse:number,
  bookName:string,
  content:string,
  date:Date

}


export const bookmarkStore = createStore({name:'history'}, withEntities<Bookmark,'_id'>({idKey:'_id'}))

export const persist = persistState(bookmarkStore,{
  key:'bookmark@store',
  storage:localStorageStrategy
})


@Injectable({ providedIn: 'root' })
export class BookmarkRepository {
  bookmark$ = bookmarkStore.pipe(selectEntities(),tap(b=> console.log({bookmarkstore:b})));

  addBookmark(bookmark: Bookmark | Bookmark[]) {
     console.log({bookmark:bookmark})
    bookmarkStore.update(addEntities(bookmark));
  }

  deleteBookmark(id: Bookmark['_id']) {
    bookmarkStore.update(deleteEntities(id));
  }
}
