import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-star-add',
  templateUrl: './star-add.component.html',
  styleUrls: ['./star-add.component.scss'],
})
export class StarAddComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
