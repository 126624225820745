import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {BehaviorSubject,combineLatest,interval,Subject,Subscription,} from 'rxjs';
import { IPrintParam, PrintParam } from '../../models/print-param';
import * as _ from 'lodash';
import {startWith,tap,withLatestFrom,take,map,takeUntil,} from 'rxjs/operators';
import { PrintSnackbarComponent } from '../print-snackbar/print-snackbar.component';
import { jsPDF } from 'jspdf';

import * as htmlToImage from 'html-to-image';
import { MatSnackBar } from '@angular/material/snack-bar';
import {MatBottomSheet,MatBottomSheetRef,} from '@angular/material/bottom-sheet';
import { BottomComponent } from '../bottom/bottom.component';
import {BreakpointObserver,Breakpoints,BreakpointState,} from '@angular/cdk/layout';
import { PrintService } from '../../services/print.service';
import { SharedRepository, sharedStore } from 'src/app/shared/shared.repository';
import { PrintRepo } from '../../print.repository';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ShareLinkDialogComponent } from 'src/app/shared/components/share-link-dialog/share-link-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { inject } from '@angular/core/testing';
// declare const jsPDF: any

export interface GenParam {
  e: HTMLElement;
  pageOrientation: 'l' | 'p';
  pageSize: string;
}

@Component({
  selector: 'app-print-toolbar',
  templateUrl: './print-toolbar.component.html',
  styleUrls: ['./print-toolbar.component.scss'],
})
export class PrintToolbarComponent implements OnInit {
  counter = interval(100);
  sub: Subscription;
  downloadSpinner$ = this.ps.exporting$;



  bottomSheetScreens = [

    // Breakpoints.TabletPortrait,
    Breakpoints.HandsetPortrait
  ];

  bottomSheetFlag$ = this.shr.isPortaitDevice$.pipe(tap(st => console.log({ bott: st })))

  // this.breakpointObserver
  //   .observe(this.bottomSheetScreens)
  //   .pipe(
  //     tap(st=>console.log({st})),
  //     map((state) => state.matches ),
  //     tap((s) => console.log({ s }))
  //   );

  end = new Subject<void>();
  end$ = this.end.asObservable();

  ngOnDestroy(): void {
    this.end.next();
    this.end.complete();
  }

  constructor(
    private snackBar: MatSnackBar,
    private bottomSheet: MatBottomSheet,
    private breakpointObserver: BreakpointObserver,
    private ps: PrintService,
    private shr: SharedRepository,
    private pr: PrintRepo,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(Location) private readonly loc: Location
  ) { }

  printParam$ = this.pr.printParam$;

  imageBaseUrl$ = this.shr.imageBaseUrl$;


  process(p: any) {
    // console.log({ p });
    this.pr.updatePrintParam(p);
  }

  sharePrintUrl(p: IPrintParam) {
    console.log({ shareUrl: p, route: this.router.url });

    let url = window.location.origin + this.router.url;

    this.dialog.open(ShareLinkDialogComponent, {
      panelClass: 'shareDialogPanel',
      width: '300px',
      //  height:'300px',
      position: { top: '50px', right: '50px' },
      data: {
        linkUrl: url,
        JsonParam: _.pickBy(p, (v, key) => key != 'lookups'),
      },
    });
  }

  startCounter(prop: string, inc: boolean) {
    let props = prop.split(',');

    this.sub = this.counter
      .pipe(
        startWith(1),
        withLatestFrom(this.printParam$),
        takeUntil(this.end$)
      )
      .subscribe(([c, p]) => {
        // console.log({ c, p, prop });

        props.forEach((propT) => {
          if (inc) {
            _.set(p as any, propT, _.get(p as any, propT) + 1);
          } else {
            _.set(p as any, propT, _.get(p as any, propT) - 1);
          }
        });
        // this.printParam.next(p);
        // this.pr.updatePrintParam(p);
        this.process(p);
        console.log({ c, p, prop, ppp: _.get(p as any, prop) });
      });

    // setInterval(function () { l; console.log({ a, b, c }); }, 1000);
    // console.log({ a, b, c });
  }

  stopCounter() {
    // clearInterval();
    if (this.sub) {
      this.sub.unsubscribe();
    }

    console.log('stopped...');
  }

  openBottomSheet() {
    this.bottomSheet.open(BottomComponent, { panelClass: 'panel2' });
  }

  ngOnInit(): void { }

  exportPdf() {
    this.ps.exportPdf();
  }
  
  uploadFinished(event:any,printParam:IPrintParam){
    this.ps.setPartBBackgroundImage(event,printParam)
  }

  uploadAFinished(event:any,printParam:IPrintParam){
    this.ps.setPartABackgroundImage(event,printParam)
  }
}
