import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {  SharedModule } from "../shared/shared.module";

import { FormsModule } from "@angular/forms";
import { PrintCanvasComponent } from './components/print-canvas/print-canvas.component';
import { PrintHandleComponent } from './components/print-handle/print-handle.component';
import { TextEditComponent } from './components/text-edit/text-edit.component';
import { PrintComponent } from './components/print/print.component';
import { PrintSnackbarComponent } from './components/print-snackbar/print-snackbar.component';
import { BottomComponent } from './components/bottom/bottom.component';
import { PrintToolbarComponent } from './components/print-toolbar/print-toolbar.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { ImageEditorComponent } from './components/image-editor/image-editor.component';

@NgModule({
  declarations: [
    PrintComponent,
    PrintSnackbarComponent,
    BottomComponent,
    PrintToolbarComponent,
    PrintCanvasComponent,
    PrintHandleComponent,
    TextEditComponent,
    GalleryComponent,
    ImageEditorComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule
  ],
  exports:[
    PrintCanvasComponent
  ]
})
export class PrintModule { }
