import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { combineLatest } from 'rxjs';
import { mergeMap, take, tap } from 'rxjs/operators';
import { BackendService } from 'src/app/shared/services/backend.service';
import { SharedRepository } from 'src/app/shared/shared.repository';
import { BookmarkRepository } from '../bookmark.repository';

@Injectable({
  providedIn: 'root'
})
export class BookmarkService {

  constructor(private sr: SharedRepository,
    private bes:BackendService,
    private br: BookmarkRepository) { }

  bookmark$ = this.br.bookmark$

  saveBookMark(content: any){
 
    _.chain(content).map(b=> ({...b,_id:Date.now(),date: new Date()})).value().forEach(
      bm=>{ console.log({bm})
         this.br.addBookmark( bm )}
    )
    

    this.sr.bookmarkUrl$.pipe(
      // map(a => a + "/bookmark"),
      take(1),
      tap(
        u =>  this.bes.post(u, {user:'temp', bookmark:content, date: new Date()})
      ),
      tap(a => console.log("savedbookmark"))
      
      ).subscribe();

     // this.br.addBookMark(content)
  
  }

  getBookmark$(){
  return   combineLatest([this.sr.bookmarkUrl$,this.sr.main$]).pipe(
      take(1),
      mergeMap(([bu,m])=>{
        return this.bes.get<any>(bu,{version:m.code})
      })
    )
  }
}
