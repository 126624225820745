import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from "lodash";
import { map, mergeMap, Observable, startWith, tap, withLatestFrom } from 'rxjs';
import { CategoriesService } from '../../services/categories.service';
import { StarsService } from '../../services/stars.service';

export interface StarData{
  star: any,
  topics:any
}

@Component({
  selector: 'app-star-edit',
  templateUrl: './star-edit.component.html',
  styleUrls: ['./star-edit.component.scss'],
})
export class StarEditComponent implements OnInit {
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  
  constructor(@Inject(MAT_DIALOG_DATA) public data: StarData,
  public dialogRef: MatDialogRef<StarEditComponent>,
  private ss: StarsService) {

    
   }
   topicCtrl = new UntypedFormControl()

   filteredTopics$  = this.topicCtrl.valueChanges.pipe(
    startWith(null),
   
    //  tap(c=>console.log({c})),
    map( t =>{
      // var topics = c.find(c=>c.type='star').categories.map(cc=>cc.name)
      // return topics
      return t ?  this.topics.filter(tt=> tt.includes(t)) : this.topics.slice()
    } ),
    // map( (t )=>  [1,2]),
    // tap(cc=>console.log({cc}))

  );



   star = this.data.star;
   topics= this.data.topics;
  

  ngOnInit(): void {
  }
  closeDialog(){
    this.dialogRef.close();
  }
  remove(star: any, t): void {
    const index = _.findIndex(star.topics,tt=>tt==t);
    console.log({star,index,t})
    if (index >= 0) {
      star.topics.splice(index, 1);
      this.updateStar(star)
    }
    console.log({star})
    // console.log({i:index,c:catType.categories, cat})
    // this.cs.updateCategories(catType)
  }
  add(star:any,event: MatChipInputEvent): void {
    const v = (event.value || '').trim();
        
    var validTopic = _.findIndex(this.topics,t=>t==v) >-1
    var index = _.findIndex(star.topics, (t:any)=>t.toLowerCase()== v )
    console.log({v,star,index})
    if (v && index < 0 && validTopic ) {
      
      star.topics.push(v);
      this.updateStar(star)
      // this.cs.updateCategories(catType)
    }
    event.chipInput!.clear();
   
    console.log({ v });
   
  }
  selected( event: MatAutocompleteSelectedEvent): void {
    console.log({v:event.option.viewValue,s:this.star})
    this.star.topics.push(event.option.viewValue);
    // topics.push(event.option.viewValue);
    console.log({star:this.star})
    // this.topicInput.nativeElement.value = '';
    this.topicCtrl.setValue(null);
    this.updateStar(this.star);
  }

  updateStar(star){
    console.log({starupdate:star})
    this.ss.updateStar({_id:star._id,value:star.id,topics:star.topics})
  }
 
}
