import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, delay, map, mergeMap, ReplaySubject, Subject, takeUntil, tap, withLatestFrom } from 'rxjs';
import { SharedRepository } from 'src/app/shared/shared.repository';
import { BibleToolbarService } from '../../services/bible-toolbar.service';
import { ChapterService } from '../../services/chapter.service';
import { HistoryService } from '../../services/history.service';
import * as _ from "lodash";
import { BookmarkService } from '../../services/bookmark.service';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-chapter',
  templateUrl: './chapter.component.html',
  styleUrls: ['./chapter.component.scss'],
})
export class ChapterComponent implements OnInit, AfterViewInit {

  currentChapter = new ReplaySubject<number>(1)
  currentChapter$ = this.currentChapter.asObservable()

  currentBook = new ReplaySubject<number>(1)
  currentBook$ = this.currentBook.asObservable()
  currentVerse = new ReplaySubject<number>(1)

  currentVerse$ = this.currentVerse.asObservable();

  max$ =
    combineLatest([this.currentBook$, this.shr.versions$])
      .pipe(
        tap(([a, b]) => console.log({ a, b })),
        map(([c, v]) => v.find(c => c.code == 'niv')?.content.find(d => d.id == c)?.chapters)
      )

  selectedItems: Set<any> = new Set<any>();

  searchKey$ = this.ss.searchKey$;


  end = new Subject<void>()
  end$ = this.end.asObservable();

  isEsvMain$ = this.shr.isEsvMain$
  constructor(private cs: ChapterService, private router: Router,
    private route: ActivatedRoute,
    private bts: BibleToolbarService,
    @Inject(DOCUMENT) private doc: Document,
    private shr: SharedRepository,
    private hs: HistoryService,
    private bs: BookmarkService,
    private ss: SearchService) {
    bts.hideDeleteButton()
    bts.showChapterNav();

    this.bts.goPrevChap$
      .pipe(
        tap(_ => console.log('chapter')),
        withLatestFrom(this.currentBook$, this.currentChapter$),
        tap(([a, b, c]) => {
          console.log({ a, b, c })
          let preChap = Number(c) > 1 ? c - 1 : c;

          // this.router.navigate([`/chapter/${this.book}/${preChap}`])
          this.router.navigate([{
            outlets: {
              primary: ['chapter', b, preChap],
              toolbarLeft: ['chapter', b, preChap], toolbarRight: null
            }
          }]);
        })
        ,
        takeUntil(this.end$)
      )
      .subscribe()


    this.bts.goNextChap$
      .pipe(
        tap(() => console.log('chapter')),
        withLatestFrom(this.currentBook$, this.currentChapter$, this.max$),
        tap(([a, b, c, m]) => {
          let nextChap = Number(c) >= Number(m) ? m : Number(c) + 1;
          console.log({ a, b, c, m, nextChap })
          // this.router.navigate([`/chapter/${this.book}/${nextChap}`])
          this.router.navigate([{
            outlets: {
              primary: ['chapter', b, nextChap],
              toolbarLeft: ['chapter', b, nextChap], toolbarRight: null
            }
          }]);
        }),
        takeUntil(this.end$)
      ).subscribe();

    bts.saveBookmarkAction$
      .pipe(//skip(1),
        withLatestFrom(shr.main$, this.currentBook$, this.currentChapter$, this.chapterTitle$),
        tap(([, m, b, c, ct]) => {
          console.log({ bookaction: m })


          let zz = _.map(Array.from(this.selectedItems), a =>
          ({
            book: b, chapter: c, verse: Number(a.id), date: new Date(), bookName: ct.book, content: a.verses[0].content
          }))
          console.log({ zz })

          this.bs.saveBookMark(zz);

          this.selectedItems.clear();
          this.bts.hidePrintButton()

          this.bts.hideBookmarkButton()

        }),
        // tap(az=>console.log({az})),
        takeUntil(this.end$)
      ).subscribe();

    bts.printAction$
      .pipe(
        tap(() => console.log('chpaterprint Triggered ....')),

        withLatestFrom(this.shr.main$, this.currentBook$, this.currentChapter$, this.chapterTitle$),
        tap(([aa, m, b, c, bn]) => {
          console.log({ selected: this.selectedItems })
          let zz = _.map(Array.from(this.selectedItems), a =>
          ({
            bookId: b, bookName: bn.book, verseId: a.id,
            chapterId: c, verseT: a.verses.find(v => v.version == m.code).content,
            date: new Date(), version: m.code
          }));
          console.log({ selected: this.selectedItems })
          // this.eb.set(Events.PrintBibleEntity, zz);
          // this.eb.set(Events.ShowBookMark, false);
          shr.printContent(zz)
          console.log({ chapterPrintCont: zz, selected: this.selectedItems })
          this.router.navigate([{ outlets: { primary: ['print'], toolbarLeft: null, toolbarRight: ['print'] } }]);
        }
        )
        ,
        takeUntil(this.end$)
      ).subscribe()

  }

  verses$ = this.route.params.pipe(
    mergeMap(p => {
      //set current book,chapter
      console.log({ b: p['book'], c: p['chapter'] })
      this.currentBook.next(Number(p['book']));
      this.currentChapter.next(Number(p['chapter']));
      this.currentVerse.next(Number(p['verse']))

      ///save history
      let z = { book: Number(p['book']), chapter: Number(p['chapter']) };
      this.hs.saveHistoryLog(z)
      console.log({ saveHistory: z })

      return this.cs.verses$(p['book'], p['chapter'])
    }),
    // tap(console.log)

  )



  chapterTitle$ = this.route.params.pipe(
    mergeMap(p => {
      return this.cs.chapterTitle$(p['book'], p['chapter'])
    })
  )

  ngAfterViewInit(): void {
    this.currentVerse$.pipe(
      delay(200),
      tap((v) => {
        if (Number(v) < 4) return; //skip scroll
        console.log({ v })
        console.log(this.doc.getElementById(`search_${v}`));
        // this._vs.scrollToAnchor(`search_${b}`)
        //alternative
        this.doc.getElementById(`search_${v}`)?.scrollIntoView({ block: 'center' });
        // this.doc.getElementById(`myList`).scrollTop -= 500;
      }
      )
    ).subscribe()
  }

  onClick(item: any) {
    if (this.selectedItems.has(item)) {
      this.selectedItems.delete(item)
      if (_.size(this.selectedItems) < 1) {
        // this._ser.showBookMarkFlag.next(false);
        // this.eb.set(Events.ShowBookMark, false);
        // this.eb.set(Events.ShowPrint, false)
        this.bts.hidePrintButton()

        this.bts.hideBookmarkButton()
        // this.br.hideBookmarkButton()


      }
    } else {
      this.selectedItems.add(item)
      // this._ser.showBookMarkFlag.next(true);
      // this.eb.set(Events.ShowBookMark, true);
      // this.eb.set(Events.ShowPrint, true)
      this.bts.showPrintButton()
      this.bts.showBookmarkButton()
      console.log({ click: item })

    }
    console.log({ selectedItem: this.selectedItems })


  }

  ngOnInit() { }

  ngOnDestroy(): void {
    this.bts.hideChapterNav();
    this.end.next();
    this.end.complete();
  }
}
