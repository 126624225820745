import { Component, OnInit } from '@angular/core';
import { map, tap } from 'rxjs/operators';
// import { EventBusService, Events } from 'src/app/core/services/event-bus.service';
// import { IVersion } from '../../models/version';
import { IVersionSetting } from "./../../models/iversion-setting";
import { MatListOption, MatSelectionListChange } from '@angular/material/list';
import { SelectionModel } from '@angular/cdk/collections';
import { MatRadioChange } from '@angular/material/radio';
import * as _ from "lodash";
// import { BooksService } from 'src/app/bible/services/books.service';
import { SettingService } from '../../services/setting.service';
import { ImageListItem } from '../../models/image-list-item';


@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {
  // versions: IVersion[];
  // versions1$ = 
  //   this.eb.get(Events.SettingVersions)
  //     .pipe(tap(a => { this.versions = a.versions, console.log({ a }) })
  //       ,map(b=>b.versions)
  //     );

  versions$ =
    this.ss.versionSetting$
      .pipe(tap(a => {
        //  this.versions = a as IVersion[] 
        //  console.log({ a })
      })

      );

  constructor(private ss: SettingService) {

  }

  onVersionChange(v: IVersionSetting[], e: SelectionModel<MatListOption>): void {
    // console.log({e,v});
    let sel = e.selected.map(a => a.value.code);

    v.forEach(a => {
      a.loaded = _.includes(sel, a.code) || a.main;

    });
    // console.log({e,v});
    // this._file.settings.next(this.versions);
    // this._me.cast(LocalEvent.SettingVerEntityUpdate, this.versions);
    // this._file.updateSettingVer(this.versions);
    // remove event bus
    // this.eb.set(Events.SettingVersionsUpdate, this.versions);
    // this.ss.updateSettingVer$({ versions: this.versions }).subscribe();
    this.ss.updateVersion(v);
  }

  onRadio(v: IVersionSetting[], e: MatRadioChange) {
    // console.log({v,e})
    let sel = e.value;
    v.forEach(a => {
      // console.log({a,sel});
      a.main = sel == a.code;

      a.loaded = a.loaded || a.main
    })

    this.ss.updateVersion(v)
    //  console.log({v,e})

    // console.log({radio:this.versions})
  }


  ngOnInit(): void {
  }


  imageList1$ =this.ss.getAppBackgroundImages$().pipe(map(i=>_.map(i, ii=>ii.listItem)))

  imageList: ImageListItem[] = [
    {
      src: "../../../../assets/appbackground/color1.png",
      caption: 'color1',
      code: 'color1.png'
    },
    {
      src: "../../../../assets/appbackground/color2.png",
      caption: 'color2',
      code: 'color2.png'
    },
    {
      src: "../../../../assets/appbackground/rock.jpg",
      caption: 'rock',
      code: 'rock.jpg'
    },
    {
      src: "../../../../assets/appbackground/rock1.png",
      caption: 'rock1',
      code: 'rock1.png'
    },
    {
      src: "../../../../assets/appbackground/rock2.jpg",
      caption: 'rock2',
      code: 'rock2.jpg'
    },
    {
      src: "../../../../assets/appbackground/old.jpg",
      caption: 'old',
      code: 'old.jpg'
    },
    {
      src: "../../../../assets/appbackground/yellow-parchment.jpg",
      caption: 'yellow-parchment',
      code: 'yellow-parchment.jpg'
    }
  ]
  backgroundImageClick(img: ImageListItem) {
    console.log({ img })
    var bUrl = img.src;//.substring(10)
    this.ss.updateAppbackground(bUrl);
  }

  useLanguage(l:string){
    this.ss.useLanguage(l)
    console.log({l})
    
  }
  appLanguageData = [
    {
      value:'en-CA',
      caption:'en-CA'
    },
    {
      value:'zh-CN',
      caption:'zh-CN'
    },
    {
      value:'zh-HK',
      caption:'zh-HK'
    }
  ]
  currentLanguage$ =this.ss.currentLanguage$ 

  public response: {dbPath: ''} ={dbPath: ''} ;

  public uploadFinished = (event:any) => {
    // this.response = event;

    this.ss.setAppBackgroundImage(event);

  }

  /**
   * used for server customized images
   * @param serverPath 
   * @returns 
   */
  public createImgPath = (serverPath: string) => {
    return `http://localhost:5000/${serverPath}`;
  }

  updateAppbackgroundCustomized(p:string){
    this.ss.updateAppbackgroundCustomized(p);
  }
}
