import { Component, ElementRef, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet, MatBottomSheetContainer, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { BehaviorSubject, interval, Subject, Subscription } from 'rxjs';
import { IPrintParam, PrintParam } from "../../models/print-param";
import * as _ from "lodash";
// import { EventBusService, Events } from "../../../core/services/event-bus.service";
import { startWith, tap, withLatestFrom, take, map, takeUntil } from 'rxjs/operators';
import { SharedRepository } from 'src/app/shared/shared.repository';
import { PrintRepo } from '../../print.repository';
import { PrintService } from '../../services/print.service';



@Component({
  selector: 'app-bottom',
  templateUrl: './bottom.component.html',
  styleUrls: ['./bottom.component.scss']
})
export class BottomComponent implements OnInit,OnDestroy {

  counter = interval(100);
  sub: Subscription;
  downloadSpinner: boolean = false;

  end = new Subject<void>()
  end$ = this.end.asObservable();
  

  ngOnDestroy(): void {
    this.end.next();
    this.end.complete();
  }

  constructor(
    private bottomSheetRef: MatBottomSheetRef<BottomComponent>,
    private shr: SharedRepository,
    private pr: PrintRepo,
    private e: ElementRef,
    private ps:PrintService
  ) {

  }
  imageBaseUrl$ = this.shr.imageBaseUrl$;
  
  printParam$ =  this.pr.printParam$ 

  ngOnInit(): void {
    
  }
  process(p: any) {
     console.log({ p });
    this.pr.updatePrintParam(p)
    
  }

  panelClassNum:number = 2

  incHeight(){
    // console.log({paneliec1:this.panelClassNum})
    if (this.panelClassNum >=3){
      this.panelClassNum=3;
      return;
    }
    let e = this.e.nativeElement.parentElement as HTMLElement;
    
    e.classList.remove(`panel${this.panelClassNum}` )
    this.panelClassNum +=1;
    e.classList.add(`panel${this.panelClassNum}` )
    // console.log({paneliec2:this.panelClassNum})
  }
  decHeight(){

    // console.log({pabeldec1:this.panelClassNum})
    if (this.panelClassNum<=1){
      this.panelClassNum=1;
      return;
    }
    let e = this.e.nativeElement.parentElement as HTMLElement;
    
    e.classList.remove(`panel${this.panelClassNum}` )
    this.panelClassNum -=1;
    e.classList.add(`panel${this.panelClassNum}` )
    // console.log({pabeldec2:this.panelClassNum})
  }

  startCounter(prop: string, inc: boolean) {
    let props = prop.split(',');

    this.sub = this.counter.pipe(startWith(1), withLatestFrom(this.printParam$),takeUntil(this.end$))
      .subscribe(([c, p]) => {
        // console.log({ c, p, prop });

        props.forEach(propT => {
          if (inc) { _.set(p as any, propT, _.get(p as any, propT) + 1); }
          else {
            _.set(p as any, propT, _.get(p as any, propT) - 1);
          }
        });
        // this.printParam.next(p);
        this.process(p);
        console.log({ c, p, prop, ppp: (_.get(p as any, prop)) });
      });

    // setInterval(function () { l; console.log({ a, b, c }); }, 1000);
    // console.log({ a, b, c });
  }

  stopCounter() {
    // clearInterval();
    if (this.sub) {
      this.sub.unsubscribe();
    }
    // console.log('stopped...');
  }

  uploadFinished(event:any,printParam:IPrintParam){
    this.ps.setPartBBackgroundImage(event,printParam)
  }

  uploadAFinished(event:any,printParam:IPrintParam){
    this.ps.setPartABackgroundImage(event,printParam)
  }

}
