import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/shared/services/backend.service';
import { SharedRepository } from 'src/app/shared/shared.repository';
import { take,switchMap, mergeMap } from "rxjs";
import { FeatherService } from 'src/app/shared/services/feather.service';
@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss'],
})
export class BoardComponent implements OnInit {

  editCat = false;
  editStar = false;
  editImg = false;
  editVersions = false;
  editLog = false;
  editUser = false;
  editFeedback = false;
  

  constructor(private bs: BackendService, private sr:SharedRepository, private fs:FeatherService) { }

  ngOnInit() {}

  cacheReset() {
    
    this.sr.cacheResetPath$.pipe(
      take(1),
      mergeMap(url=> this.fs.find(url))
    ).subscribe();

    // this.sr.cacheResetUrl$.pipe(
    //   take(1),
    //   switchMap((u:string) => this.bs.get(u))
    // ).subscribe((_) => {console.log('cache reset');});

  }
}
