import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tou',
  templateUrl: './tou.component.html',
  styleUrls: ['./tou.component.scss'],
})
export class TouComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
