import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { SharedRepository } from 'src/app/shared/shared.repository';

@Injectable({
  providedIn: 'root'
})
export class BookService {

  constructor(private sr: SharedRepository) { }

  bookName$ = book =>
    combineLatest([this.sr.versions$, this.sr.main$])
      .pipe(

        map(([v, m]) => {
          return v?.find(vv => vv.code == m.code)?.content?.find(c => c.id == book)?.name
        }))

  bookChapters$ = book =>
    combineLatest([this.sr.versions$, this.sr.main$])
      .pipe(
        map(([v, m]) => {
          var b=  v?.find(vv => vv.code == m.code)?.content?.find(c => c.id == book)
          return {book: b.id, chapters:b?.chapters}
        }))
}
